import { ThemeContext } from "../../Context/Color";
import { useContext, useRef, useState, useEffect, ReactNode } from "react";

import "./Style.css";
import {
  Task,
  updateTaskDetails,
  updateDueDate,
  deleteTask,
  taskCompleted,
  flagTask,
  areTasksEqual,
  moveTask,
} from "../../Data/Task";
import Button from "../../UI/Atoms/Button/Button";
import Icon from "../../UI/Atoms/Icon/Icon";
import EditBox from "../../UI/Atoms/EditBox/EditBox";
import { DateFormat } from "../../Util/Date";
import PopupMenu from "../../UI/Molecules/PopupMenu/PopupMenu";
import { useOverlay } from "../../Context/OverlayProvider";
import { useSnackbar } from "../../Context/SnackbarProvider";
import WarnDlg from "../../UI/Organizm/WarnDlg/WarnDlg";
import { useData } from "../../Context/Data";
import DueDate from "../DueDate/DueDate";
import { Timestamp } from "firebase/firestore";
import Reminders from "../Reminders/Reminders";
import { Reminder, formatReminderString } from "../../Data/Reminder";
import MoveDlg from "../../UI/Organizm/MoveDlg/MoveDlg";
import Tooltip from "@mui/material/Tooltip";
import Spinner from "../../UI/Atoms/Spinner/Spinner";

type TaskProps = {
  userID: string;
  Object: Task;
  projectID: string;
  userTimeZone: string;
  projectName: string;
  todayView: boolean;
  upcomingView: boolean;
  isMobile: boolean;
  onClose: () => void;
  onOpenTask: () => void;
  onMoveToTop: (taskID: string) => void;
  onChangeList: (projectID: string, taskID: string) => void;
  taskReminders: Reminder[];
  onEdit: (edit: boolean) => void;
};

const TaskPage = (props: TaskProps) => {
  const themeContext = useContext(ThemeContext);
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const dataProvider = useData();

  const [taskRecord, setTaskRecord] = useState(props.Object);

  const [taskReminders, setTaskReminders] = useState(props.taskReminders);

  const [taskFlagged, setTaskFlagged] = useState(
    props.Object ? props.Object.priority === 1 : false
  );

  const { addOverlay, removeOverlay } = useOverlay();

  const { addSnackbar } = useSnackbar();

  const [mobile, setMobile] = useState(props.Object?.pushNotifications ?? true);

  const [email, setEmail] = useState(props.Object?.emailNotifications ?? true);

  const [desktop, setDesktop] = useState(
    props.Object?.desktopNotifications ?? true
  );

  const [editMode, setEditMode] = useState(false);
  const [focusTaskName, setFocusTaskName] = useState(true);

  const dateFormatter = new DateFormat();

  const [taskName, setTaskName] = useState(
    props.Object ? props.Object.name : ""
  );
  const [taskDescription, setTaskDescription] = useState(
    props.Object ? props.Object.description : ""
  );

  const onDelete = () => {
    addOverlay(
      <WarnDlg
        onCancel={() => {
          removeOverlay();
        }}
        onApprove={() => {
          addSnackbar("Task deleted");
          deleteTask(props.userID, taskRecord.id!);
          removeOverlay();
          props.onClose();
        }}
        isMobile={props.isMobile}
        icon={<Icon deleteIcon={true} />}
        applyBtnRed={true}
        title="Are you sure?"
        subTitle={`"${taskRecord.name}" will be deleted forever.`}
        cancelBtnText="CANCEL"
        approveBtnText="YES, DELETE IT"
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        width: props.isMobile ? "" : "",
        marginTop: 100,
      },
      true
    );
  };

  const onEditTask = () => {
    setEditMode(true);
  };

  if (!themeContext) {
    throw new Error("ThemeContext not provided from a ThemeProvider component");
  }

  const moreOptionsPopup = [
    {
      label: "Edit Task",
      onClick: onEditTask,
      color: themeContext.textColor,
      iconComponent: <Icon editIcon={true} />,
    },
    {
      label: "Delete Task",
      onClick: onDelete,
      color: "red",
      iconComponent: <Icon deleteIcon={true} />,
    },
  ];

  useEffect(() => {
    if (
      dataProvider.getActiveTaskRecord() !== null &&
      !areTasksEqual(taskRecord, dataProvider.getActiveTaskRecord()!)
    ) {
      setTaskFlagged(dataProvider.getActiveTaskRecord()?.priority === 1);
      setTaskRecord(dataProvider.getActiveTaskRecord()!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider.getActiveTaskRecord()]);

  useEffect(() => {
    props.onEdit(editMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    if (!taskRecord || taskRecord.name == null) return;
    setTaskName(taskRecord.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskRecord ? taskRecord.name : null]);

  useEffect(() => {
    if (!taskRecord) return;
    setTaskDescription(taskRecord.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskRecord ? taskRecord.description : null]);

  const onSave = () => {
    if (taskRecord === null) return;
    let _taskName = nameRef.current?.value;
    _taskName = _taskName?.trim();
    if (_taskName === "") return;

    let _description = descriptionRef.current?.value;
    _description = _description?.trim();

    setTaskName(_taskName!);
    setTaskDescription(_description!);
    updateTaskDetails(_taskName!, _description!, props.userID, taskRecord.id!);
    setEditMode(false);
  };

  const onCompleteTask = () => {
    props.onClose();
    if (taskRecord.id) {
      taskCompleted(true, props.userID, taskRecord.id);
      addSnackbar("✌️ Completed", 8, "Undo", () => {
        if (taskRecord.id) taskCompleted(false, props.userID, taskRecord.id);
      });
    }
  };

  const onFlagTask = () => {
    setTaskFlagged(!taskFlagged);
    flagTask(!taskFlagged, props.userID, taskRecord.id!);
    if (!taskFlagged) props.onMoveToTop(taskRecord.id!);
  };

  const onDueDate = () => {
    addOverlay(
      <DueDate
        reminder={false}
        firstDayOfWeek={
          dataProvider.getUser() === undefined
            ? 2
            : dataProvider.getUser()!.firstDayOfWeek
        }
        currentDateWithTime={taskRecord.dueDateWithTime!}
        isMobile={props.isMobile}
        currentDate={
          taskRecord.dueDate === null
            ? undefined
            : taskRecord.dueDate?.toDate()!
        }
        onClose={() => {
          removeOverlay();
        }}
        onSave={(date, withTime) => {
          //  setDueDateHasTime(withTime);
          // setDueDate(date);
          if (date !== null) {
            updateDueDate(
              withTime,
              Timestamp.fromDate(date),
              props.userID,
              taskRecord.id!
            );
          } else {
            updateDueDate(false, null, props.userID, taskRecord.id!);
          }
          removeOverlay();
        }}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,
        marginTop: 100,
      },
      true
    );
  };

  const onSaveReminders = async (reminders: Reminder[]) => {
    setTaskReminders(reminders);
  };

  const getProjectName = (projectID: string): string => {
    const projects = dataProvider.getLists();

    const project = projects.find((p) => p.id === projectID);

    return project ? project.name : "Project not found";
  };

  const remindersToText = (): ReactNode => {
    const filteredReminders = taskReminders
      .filter((reminder) => !reminder.deleted)
      .sort((a, b) => {
        const dateA = a.time.toDate();
        const dateB = b.time.toDate();
        return dateA.getTime() - dateB.getTime();
      });
    return (
      <>
        {filteredReminders.map((reminder) => {
          let formattedDate = formatReminderString(
            reminder,
            true,
            props.userTimeZone
          );

          //let formattedDate = dateFormatter.formatDate(
          // reminder.time.toDate(),
          //true
          // );

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "24px",
              }}
              key={reminder.id}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <Button
                  id="current_reminder"
                  padding={"0px"}
                  label={formattedDate}
                  iconComponent={<Icon alarmIcon={true} />}
                  textButton={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    onReminders();
                  }}
                ></Button>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  const onReminders = () => {
    addOverlay(
      <Reminders
        onClose={(reminders: Reminder[]) => {
          removeOverlay();
        }}
        userTimeZone={props.userTimeZone}
        onCancel={() => {}}
        updateUI={(
          reminders: Reminder[],
          mobile: boolean,
          desktop: boolean,
          email: boolean
        ) => {
          setMobile(mobile);
          setDesktop(desktop);
          setEmail(email);
          onSaveReminders(reminders);
        }}
        onSave={(reminders: Reminder[]) => {
          removeOverlay();
        }}
        userID={props.userID}
        isMobile={props.isMobile}
        taskID={props.Object.id}
        editMode={true}
        currentReminders={taskReminders}
        mobile={mobile}
        desktop={desktop}
        email={email}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,
        marginTop: 100,

        width: "100%",
      },
      true
    );
  };
  const onMove = () => {
    addOverlay(
      <MoveDlg
        listSelect={false}
        onProjectClick={(projectID: string, projectName: string) => {
          removeOverlay();
          moveTask(projectID, props.userID, taskRecord.id!);
          if (props.todayView || props.upcomingView) return;
          props.onClose();

          addSnackbar("👋 Moved to " + projectName, 8, "Open", () => {
            props.onChangeList(projectID, taskRecord.id!);
          });
        }}
        isMobile={props.isMobile}
        projects={dataProvider.getLists()}
        taskName={taskRecord.name}
        taskID={taskRecord.id!}
        projectID={taskRecord.project!}
        projectName={props.projectName}
      />,
      {
        closeOnClickOutside: true,
        marginBottom: 30,
        centerX: true,
        marginTop: 100,
      },
      true
    );
  };

  const getReminderQuantity = (): number => {
    const rem = taskReminders.filter((reminder) => !reminder.deleted);

    return rem.length;
  };

  const isTaskFound = (): boolean => {
    return taskRecord && taskRecord.name !== "";
  };

  if (isTaskFound() && taskRecord.deleted) props.onClose();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        wordBreak: "break-word",
        backgroundColor: !isTaskFound() ? themeContext.overlayBackground : "",

        borderRadius: "15px",
        fontSize: "24px",
        fontFamily: "Calibri",
        width: props.isMobile ? "100%" : "800px",
      }}
      className="task_page"
    >
      {!isTaskFound() && <Spinner centered={true} />}
      {isTaskFound() && (
        <div
          style={{
            display: "flex",
            backgroundColor: themeContext.overlayBackground,
            color: themeContext.headerText,
            justifyContent: "flex-end",

            paddingLeft: "6px",
            paddingRight: "6px",
            borderRadius: " 15px 15px 0 0",
          }}
          className="task_page_title"
        >
          <div
            style={{
              display: "flex",
              visibility: editMode ? "hidden" : "visible",
            }}
            className="task_page_close"
          >
            {!taskRecord.done && (
              <PopupMenu
                iconComponent={<Icon fontSize={32} moreIcon={true} />}
                textColor={themeContext?.listPopupTextColor}
                items={moreOptionsPopup}
                backgroundColor={themeContext?.listPopupColor!}
                textSize={24}
                buttonColor={themeContext.headerText}
              />
            )}

            <Button
              textButtonColor={themeContext?.textColor}
              id="close_task_page"
              actionTip="Close task page"
              actionTipPosition="right"
              label={""}
              iconComponent={<Icon closeIcon={true} />}
              textButton={true}
              onClick={() => {
                props.onClose();
              }}
            ></Button>
          </div>
        </div>
      )}

      {isTaskFound() && !editMode && !taskRecord.done && (
        <div
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "flex-end",
            backgroundColor: themeContext.overlayBackground,
          }}
        >
          <Button
            id="complete_task"
            actionTip="Mark as done"
            actionTipPosition="bottom"
            marginRight={8}
            label={""}
            iconComponent={<Icon color="green" doneIcon={true} />}
            textButton={true}
            onClick={() => {
              onCompleteTask();
            }}
          ></Button>
          <Button
            id="flag_task"
            actionTip={
              !taskFlagged ? "Set priority: important" : "Set priority: normal"
            }
            actionTipPosition="bottom"
            marginRight={8}
            label={""}
            iconComponent={
              <Icon
                color={taskFlagged ? "red" : themeContext.textColor}
                flagIcon={taskFlagged}
                flagIconOutlined={taskFlagged === false}
              />
            }
            textButton={true}
            onClick={() => {
              onFlagTask();
            }}
          ></Button>
          <Button
            id="copy_task_name"
            actionTip="Copy to clipboard"
            actionTipPosition="bottom"
            marginRight={6}
            label={""}
            iconComponent={<Icon copyIcon={true} />}
            textButton={true}
            onClick={() => {
              navigator.clipboard.writeText(taskRecord.name);
              addSnackbar("📋 Copied to clipboard");
            }}
          ></Button>
        </div>
      )}
      {editMode && isTaskFound() && (
        <div
          style={{
            display: "flex",
            backgroundColor: themeContext.overlayBackground,
            justifyContent: "flex-start",
            paddingTop: "6px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            {" "}
            <Button
              id="task_page_cancel_btn"
              label={"Cancel"}
              textButtonColor={themeContext.textColor}
              iconComponent={<Icon closeIcon={true} />}
              textButton={true}
              onClick={() => {
                setEditMode(false);
              }}
            ></Button>
          </div>

          <div>
            {" "}
            <Button
              id="task_page_save_btn"
              label={"Save"}
              iconComponent={<Icon sendIcon={true} />}
              textButton={false}
              onClick={() => {
                onSave();
              }}
            ></Button>
          </div>
        </div>
      )}

      {isTaskFound() && (
        <div
          style={{
            backgroundColor: themeContext.overlayBackground,
            maxHeight: "100%",
            height: "100%",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "auto",
            borderRadius: !props.isMobile ? " 0px 0px 15px 15px" : "",
          }}
          className="task_page_details"
        >
          <div>
            <div
              onClick={(e) => {
                if (taskRecord.done) return;
                setFocusTaskName(true);
                setEditMode(true);
              }}
              style={{
                backgroundColor: themeContext.overlayBackground,
                fontSize: "32px",
                display: "flex",
                cursor: "text",
                flex: 1,
                flexDirection: "column",
                marginBottom: "16px",
                fontWeight: "bold",
              }}
            >
              {taskRecord.done && (
                <div
                  style={{
                    backgroundColor: themeContext.overlayBackground,
                    color: themeContext?.activeItemColor,
                    fontSize: "22px",
                    display: "flex",
                  }}
                >
                  <div style={{ marginRight: "6px", cursor: "pointer" }}>
                    <Button
                      iconComponent={<Icon lock={true} />}
                      id="unlock_task"
                      textButtonColor={themeContext.activeItemColor}
                      label={`This task was completed ${dateFormatter.formatDate(
                        taskRecord.updated.toDate(),
                        true
                      )}. Click here to open it.`}
                      textButton={true}
                      onClick={() => {
                        taskCompleted(false, props.userID, props.Object.id!);
                        props.onOpenTask();
                      }}
                    />
                  </div>{" "}
                </div>
              )}
              {editMode && (
                <EditBox
                  EditRef={nameRef}
                  initialValue={taskName}
                  type="text"
                  disableBorder={true}
                  onEnterPress={() => {
                    onSave();
                  }}
                  padding={12}
                  expandVertical={true}
                  maxLength={200}
                  fontSize={24}
                  placeholder="Task name"
                  id="task_page_name_edit_box"
                  focus={focusTaskName}
                />
              )}
              {!editMode && (
                <div
                  style={{
                    marginLeft: "8px",
                    color: themeContext.textColor,
                  }}
                >
                  <Tooltip
                    enterDelay={1000}
                    arrow
                    placement="top"
                    title="Edit task name"
                  >
                    <span>{taskName}</span>
                  </Tooltip>
                </div>
              )}
            </div>
            <div
              className="task_page_description"
              style={{
                color: themeContext.textColor,
                display: "flex",
                flexDirection: "column",

                alignItems: editMode ? "" : "flex-start",
                cursor: taskDescription !== "" ? "text" : "",
              }}
              onClick={() => {
                if (taskRecord.done || taskDescription === "") return;

                setEditMode(true);

                setFocusTaskName(false);
              }}
            >
              {!editMode && (
                <div
                  style={{
                    whiteSpace: "pre-line",
                    display: "flex",
                    overflowY: "auto",
                    flexDirection: "row",
                    maxHeight: "300px",
                    flexWrap: "wrap",
                  }}
                >
                  {taskDescription !== "" && (
                    <div style={{ marginLeft: "8px" }}>{taskDescription}</div>
                  )}
                  {taskDescription === "" && !taskRecord.done && (
                    <Button
                      id="task_page_add_description"
                      actionTip="Add description"
                      actionTipPosition="right"
                      textButtonColor={themeContext.iconColor}
                      label={"Add Description"}
                      marginRight={6}
                      iconComponent={<Icon detailsIcon={true} />}
                      textButton={true}
                      onClick={() => {
                        setEditMode(true);
                        setFocusTaskName(false);
                      }}
                    ></Button>
                  )}
                </div>
              )}
              {!editMode && !taskRecord.done && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    id="task_page_add_reminders"
                    marginRight={6}
                    actionTip="Add reminders"
                    actionTipPosition="bottom"
                    label={
                      getReminderQuantity() === 0
                        ? "Reminders"
                        : `(${getReminderQuantity()})`
                    }
                    textButtonColor={themeContext.iconColor}
                    iconComponent={<Icon alarmIcon={true} />}
                    textButton={true}
                    onClick={(e) => {
                      onReminders();
                      e.stopPropagation();
                    }}
                  ></Button>
                  <Button
                    id="task_page_due_date"
                    actionTip="Add due date"
                    actionTipPosition="bottom"
                    marginRight={6}
                    textButtonColor={
                      taskRecord.dueDate &&
                      dateFormatter.dateHasPassed(
                        taskRecord.dueDate!.toDate(),
                        taskRecord.dueDateWithTime!
                      )
                        ? "red"
                        : themeContext.iconColor
                    }
                    label={
                      taskRecord.dueDate === null
                        ? "Due Date"
                        : dateFormatter.formatDate(
                            taskRecord.dueDate!.toDate(),
                            taskRecord.dueDateWithTime!
                          )
                    }
                    iconComponent={<Icon dateIcon={true} />}
                    textButton={true}
                    onClick={(e) => {
                      onDueDate();
                      e.stopPropagation();
                    }}
                  ></Button>
                  <Button
                    id="task_page_move_task"
                    actionTip="Move to another list"
                    actionTipPosition="bottom"
                    label={getProjectName(taskRecord.project!)}
                    textButtonColor={themeContext.iconColor}
                    iconComponent={<Icon listIcon={true} />}
                    textButton={true}
                    onClick={(e) => {
                      onMove();
                      e.stopPropagation();
                    }}
                  ></Button>
                </div>
              )}
              {!editMode && getReminderQuantity() > 0 && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    flexWrap: "wrap",
                    marginLeft: "8px",
                    flexDirection: "column",
                  }}
                >
                  {remindersToText()}
                </div>
              )}
              {editMode && (
                <EditBox
                  EditRef={descriptionRef}
                  initialValue={taskDescription}
                  type="text"
                  disableBorder={true}
                  expandVertical={true}
                  padding={12}
                  fontSize={20}
                  placeholder="Description"
                  id="task_page_description_edit_box"
                  focus={!focusTaskName}
                />
              )}
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                fontSize: "20px",

                alignItems: "center",
              }}
            >
              <Icon
                history={true}
                fontSize={20}
                color={themeContext.textColor}
              />
              <div
                style={{ marginLeft: "6px ", color: themeContext.textColor }}
              >
                Created{" "}
                {dateFormatter.formatDate(taskRecord.created.toDate(), true)}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskPage;

import { useAuth } from "../../Context/Auth";
import { useData } from "../../Context/Data";
import { useSnackbar } from "../../Context/SnackbarProvider";
import { ThemeContext } from "../../Context/Color";

import Icon from "../../UI/Atoms/Icon/Icon";
import { useOverlay } from "../../Context/OverlayProvider";
import "./Style.css";
import Dialog from "../../UI/Organizm/Dialog/Dialog";
import ListRow from "../../UI/Organizm/ListRow/ListRow";

import WarnDlg from "../../UI/Organizm/WarnDlg/WarnDlg";
import EditDlg from "../../UI/Organizm/EditDlg/EditDlg";
import {
  updateAgendaSettings,
  updateDaysOfWeek,
  updateName,
  updateReminderSettings,
  updateTimeZoneOnly,
} from "../../Data/User";
import { useContext, useState } from "react";
import ThemeDlg from "../../UI/Organizm/ThemeDlg/ThemeDlg";
import { LocalStorage } from "../../Util/localStorage";
import DayDlg from "../../UI/Organizm/DayPickDlg/DayPickDlg";
import TimeZoneDlg from "../../UI/Organizm/TimeZoneDlg/TimeZoneDlg";
import { deleteDevice } from "../../Data/Device";
import ReminderOptionsDlg from "../../UI/Organizm/ReminderOptions/ReminderOptions";
import AgendaOptionsDlg from "../../UI/Organizm/AgendaOptions/AgendaOptions";

type UserProps = {
  isMobile: boolean;
  userTimeZone: string;
  firstDayOfWeek: number;
  emailNotifications: boolean;
  pushNotifications: boolean;
  desktopNotifications: boolean;
  dailyAgendaTime: string;
  sendAgendaOnWeekend: boolean;
  sendDailyAgenda: boolean;
  lastDayOfWeek: number;
  remindersEmail: string;
  theme: number;
  name: string;
  email: string;
  userID: string;
  picture?: string | null;
  isAdmin?: boolean;
};

const User = (props: UserProps) => {
  const auth = useAuth();
  const { addOverlay, removeOverlay } = useOverlay();
  const dataProvider = useData();

  const storage = new LocalStorage();

  const { addSnackbar } = useSnackbar();
  const themeContext = useContext(ThemeContext);

  const [remindersEmail, setRemindersEmail] = useState(props.remindersEmail);

  const [name, setName] = useState(props.name);

  const [theme, setTheme] = useState(props.theme);

  const [pushNotifications, setPushNotifications] = useState(
    props.pushNotifications
  );
  const [desktopNotifications, setDesktopNotifications] = useState(
    props.desktopNotifications
  );
  const [emailNotifications, setEmailNotifications] = useState(
    props.emailNotifications
  );

  const [dailyAgendaTime, setDailyAgendaTime] = useState(props.dailyAgendaTime);
  const [sendDailyAgenda, setSendDailyAgenda] = useState(props.sendDailyAgenda);
  const [sendAgendaOnWeekend, setSendAgendaOnWeekend] = useState(
    props.sendAgendaOnWeekend
  );

  const [userTimeZone, setUserTimeZone] = useState(props.userTimeZone);

  const [firstDayOfWeek, setFirstDayOfWeek] = useState(props.firstDayOfWeek);

  const [lastDayOfWeek, setLastDayOfWeek] = useState(props.lastDayOfWeek);

  const getThemeName = () => {
    if (theme === 0) return "System Default";
    else if (theme === 1) return "Light";
    else if (theme === 2) return "Dark";

    return "";
  };

  const getWeekDays = (): string => {
    return getDayName(firstDayOfWeek) + " - " + getDayName(lastDayOfWeek);
  };

  const getDayName = (day: number): string => {
    switch (day) {
      case 1:
        return "Sunday";
      case 2:
        return "Monday";
      case 3:
        return "Tuesday";
      case 4:
        return "Wednesday";
      case 5:
        return "Thursday";
      case 6:
        return "Friday";
      case 7:
        return "Saturday";
      default:
        throw new Error("Invalid day number");
    }
  };

  const onDailyAgenda = () => {
    addOverlay(
      <AgendaOptionsDlg
        userID={props.userID}
        isMobile={props.isMobile}
        onSave={(
          dailyAgenda: boolean,
          weekendAgenda: boolean,
          agendaTime: string
        ) => {
          setDailyAgendaTime(agendaTime);
          setSendDailyAgenda(dailyAgenda);
          setSendAgendaOnWeekend(weekendAgenda);
          updateAgendaSettings(
            dailyAgenda,
            weekendAgenda,
            agendaTime,
            props.userID
          );
          removeOverlay();
        }}
        onCancel={() => {}}
        dailyAgenda={sendDailyAgenda}
        weekendAgenda={sendAgendaOnWeekend}
        agendaTime={dailyAgendaTime}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,

        marginTop: 8,
      },
      true
    );
  };

  const onEditRemindersEmais = () => {
    addOverlay(
      <ReminderOptionsDlg
        remindersEmail={remindersEmail}
        email={emailNotifications}
        mobile={pushNotifications}
        desktop={desktopNotifications}
        userID={props.userID}
        isMobile={props.isMobile}
        onSave={(
          mobile: boolean,
          desktop: boolean,
          email: boolean,
          remindersEmail: string
        ) => {
          setPushNotifications(mobile);
          setDesktopNotifications(desktop);
          setEmailNotifications(email);
          setRemindersEmail(remindersEmail);
          updateReminderSettings(
            remindersEmail,
            email,
            desktop,
            mobile,
            props.userID
          );
          removeOverlay();
        }}
        onCancel={() => {}}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,

        marginTop: 8,
      },
      true
    );
  };

  const onPasswordChange = () => {
    addOverlay(
      <WarnDlg
        onCancel={() => {
          removeOverlay();
        }}
        onApprove={() => {
          auth.authResetPass(auth.getUserName());
          addSnackbar("Instructions were sent to your mail.");
          removeOverlay();
        }}
        isMobile={props.isMobile}
        icon={<Icon lock={true} />}
        applyBtnRed={true}
        title={`Change my password`}
        subTitle={`Are you sure? Instructions will be sent to your email.`}
        cancelBtnText="NO"
        approveBtnText={`YES, CHANGE MY PASSWORD`}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        width: props.isMobile ? "" : "",
        marginTop: 100,
      },
      true
    );
  };

  const onTimeZone = () => {
    addOverlay(
      <TimeZoneDlg
        currentTimeZone={userTimeZone}
        userID={props.userID}
        isMobile={props.isMobile}
        onSave={(newTimeZone: string) => {
          updateTimeZoneOnly(newTimeZone, props.userID);
          setUserTimeZone(newTimeZone);

          removeOverlay();
        }}
        onCancel={() => {}}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        marginTop: 8,
      },
      true
    );
  };

  const onWeekDays = () => {
    addOverlay(
      <DayDlg
        firstDayOfWeek={firstDayOfWeek}
        lastDayOfWeek={lastDayOfWeek}
        userID={props.userID}
        isMobile={props.isMobile}
        onSave={(firstDay: number, lastDay: number) => {
          addSnackbar("Weekdays Updated");
          updateDaysOfWeek(firstDay, lastDay, props.userID);
          setFirstDayOfWeek(firstDay);
          setLastDayOfWeek(lastDay);
          removeOverlay();
        }}
        onCancel={() => {}}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        marginTop: 8,
      },
      true
    );
  };

  const onTheme = () => {
    addOverlay(
      <ThemeDlg
        currentTheme={theme}
        userID={props.userID}
        isMobile={props.isMobile}
        onSave={(theme: number) => {
          themeContext?.setDarkMode(
            theme === 2 ||
              (theme === 0 &&
                window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: dark)").matches)
          );
          addSnackbar("Theme Updated");
          storage.setValue("THEME", theme.toString());
          setTheme(theme);
          removeOverlay();
        }}
        onCancel={() => {}}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        marginTop: 8,
      },
      true
    );
  };

  const getDailyAgendaString = () => {
    return sendDailyAgenda ? "Daily Agenda at " + dailyAgendaTime : "Off";
  };

  const onEditName = () => {
    addOverlay(
      <EditDlg
        isMobile={props.isMobile}
        header="Your Name"
        placeHolder=""
        saveBtnText="Save"
        allowEmptyValue={false}
        maxLength={150}
        expandVertical={true}
        initialValue={name}
        cancelBtnText="Cancel"
        emailValidation={false}
        headerSize={24}
        onSave={(name: string) => {
          removeOverlay();
          setName(name);
          addSnackbar(`Nice to meet you, ${name}! 😊`);
          updateName(name, props.userID);
        }}
        onCancel={() => {
          removeOverlay();
        }}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,
        width: "100%",
        marginTop: 8,
      },
      true
    );
  };
  const onLogout = () => {
    addOverlay(
      <WarnDlg
        onCancel={() => {
          removeOverlay();
        }}
        onApprove={async () => {
          await deleteDevice(dataProvider.getToken(), props.userID);
          removeOverlay();
          removeOverlay();
          auth.authLogout();
          themeContext?.setDarkMode(
            window.matchMedia &&
              window.matchMedia("(prefers-color-scheme: dark)").matches
          );
          dataProvider.onSignOut();
        }}
        isMobile={props.isMobile}
        icon={<Icon signoutIcon={true} />}
        applyBtnRed={true}
        title={`Signout`}
        subTitle={`Are you sure you want to signout?`}
        cancelBtnText="NO"
        approveBtnText={`YES, SIGN OUT`}
      />,
      {
        closeOnClickOutside: true,
        centerX: true,

        width: props.isMobile ? "" : "",
        marginTop: 100,
      },
      true
    );
  };

  const iconSize = 24;

  return (
    <Dialog
      closeBar={true}
      padding="0px"
      isMobile={props.isMobile}
      saveCancel={false}
      onClose={() => {
        removeOverlay();
      }}
    >
      <div
        style={{ fontSize: "24px", fontFamily: "Calibri" }}
        className="details_box"
      >
        {props.picture && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "6px",
            }}
          >
            {props.picture && (
              <img
                style={{
                  borderRadius: "50%",
                  width: "75px",
                  height: "75px",
                  marginTop: "10px",
                }}
                src={props.picture}
                alt="user_pic"
              ></img>
            )}
          </div>
        )}

        <ListRow
          title={"Name"}
          onClick={onEditName}
          subTitle={name}
          trailingIcon={<Icon fontSize={iconSize} editIcon={true} />}
          Icon={<Icon fontSize={iconSize} userIcon={true} />}
        />

        {props.isAdmin && (
          <ListRow
            title={"Daily Agenda"}
            onClick={onDailyAgenda}
            subTitle={getDailyAgendaString()}
            trailingIcon={<Icon fontSize={iconSize} editIcon={true} />}
            Icon={<Icon fontSize={iconSize} dailyAgenda={true} />}
          />
        )}

        <ListRow
          title={"Reminder Settings"}
          onClick={onEditRemindersEmais}
          subTitle={"How to get reminded?"}
          trailingIcon={<Icon fontSize={iconSize} editIcon={true} />}
          Icon={<Icon fontSize={iconSize} alarmIcon={true} />}
        />
        <ListRow
          title={"Theme"}
          onClick={onTheme}
          subTitle={getThemeName()}
          trailingIcon={<Icon fontSize={iconSize} editIcon={true} />}
          Icon={<Icon fontSize={iconSize} themeSelection={true} />}
        />
        <ListRow
          title={"Weekdays"}
          onClick={onWeekDays}
          trailingIcon={<Icon fontSize={iconSize} editIcon={true} />}
          subTitle={getWeekDays()}
          Icon={<Icon fontSize={iconSize} dateIcon={true} />}
        />
        <ListRow
          title={"Time Zone"}
          onClick={onTimeZone}
          trailingIcon={<Icon fontSize={iconSize} editIcon={true} />}
          subTitle={userTimeZone}
          Icon={<Icon fontSize={iconSize} location={true} />}
        />
        <ListRow
          title={"Email"}
          subTitle={props.email}
          Icon={<Icon fontSize={iconSize} emailIcon={true} />}
        />

        <ListRow
          title={"Change my password"}
          subTitle={""}
          onClick={onPasswordChange}
          Icon={<Icon fontSize={iconSize} lock={true} />}
        />

        <ListRow
          title={"Signout"}
          subTitle={"See you again soon! 👋"}
          marginBottom="0px"
          onClick={onLogout}
          Icon={<Icon fontSize={iconSize} signoutIcon={true} />}
        />
      </div>
    </Dialog>
  );
};

export default User;

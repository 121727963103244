import Header from "../../Atoms/Header/Header";
import Inspiration from "../../Organizm/Inspiration/Inspiration";

type NoTasksProps = {
  completedMode: boolean;
  listID: string;
};

const NoTasks = (props: NoTasksProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {!props.completedMode && <Inspiration listID={props.listID} />}
      {props.completedMode && (
        <Header fontSize={28} text="Nothing has been completed 🤔" />
      )}

      <div style={{ marginBottom: "10px" }}></div>
    </div>
  );
};

export default NoTasks;

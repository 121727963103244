import React, { useEffect, useState } from "react";
import Header from "../../Atoms/Header/Header";

type InspirationProps = {
  listID: string;
};

const Inspiration = (props: InspirationProps) => {
  const inspirationTexts: string[] = [
    "Write it down, make it happen! 💪",
    "Plan your work, work your plan! 👷‍♀️",
    "Take action on your goals! 🔥",
    "Every small step counts! 👣",
    "Stay focused, stay productive! 🤓",
    "Success is a series of small wins! 🏆",
    "Make progress every day! 📈",
    "Consistency is key! 🔑",
    "Do something today that your future self will thank you for! 👨‍💼",
    "Don't wait for opportunities, create them! 💡",
    "Believe in yourself and your dreams! 😇",
    "Small improvements lead to big results! 📈",
    "Celebrate your progress, not just your achievements! 🎉",
    "Start where you are, use what you have, do what you can! 💪",
    "The only limit is the one you set for yourself! 🚫",
    "Push yourself, because no one else is going to do it for you! 👊",
    "Your hard work will pay off! 💰",
    "Take the first step and the rest will follow! 👣",
    "Stay committed, even when it gets tough! 💪",
    "Stay focused on your goals, not your obstacles! 👀",
    "Chase progress, not perfection! 📈",
    "Your only limit is the amount of action you take! 🚫",
    "Be consistent, be persistent, be unstoppable! 💪",
    "Small progress is still progress! 📈",
    "The journey is just as important as the destination! 🌅",
    "Set a goal, make a plan, and work towards it! 🎯",
    "Don't wait for motivation, create it! 🔥",
    "You are capable of amazing things! 🤩",
    "Believe in yourself, and you can achieve anything! 😎",
    "Be proud of your progress, no matter how small! 👏",
    "You don't have to be great to start, but you have to start to be great! 💪",
    "Your future depends on what you do today! 👀",
    "Make each day count! 🌟",
    "The only way to do great work is to love what you do! 💖",
    "Success is a journey, not a destination! 🏆",
    "You are one step closer to your goal than you were yesterday! 👣",
    "Embrace challenges as opportunities for growth!🌱",
    "Setbacks are stepping stones to success! 🚶‍♂️",
    "Dream big, work hard, and make it happen! 💪",
    "Believe in your abilities and trust the process! 🙌",
    "Consistent effort leads to remarkable achievements! 🔥",
    "Take risks and push past your comfort zone! 🚀",
    "Focus on progress, not perfection! 📈",
    "Create a vision, set goals, and pursue them relentlessly! 👊",
    "Embrace the power of resilience and bounce back stronger! 💪",
    "Let your passion be your driving force! 🔥",
    "Every challenge is an opportunity to learn and grow! 🌱",
    "Dare to dream, and then make it your reality! ✨",
    "Success is the result of determination and perseverance! 🏆",
    "Embrace the power of self-belief and unlock your potential! 💫",
    "Choose discipline over regret; take action now! ⚡️",
    "Invest in self-improvement and watch yourself thrive! 🌟",
    "Embrace the journey, for it shapes who you become! 🚶‍♀️",
    "Let your actions speak louder than your doubts! 💪",
    "Seize the day and make it extraordinary! 🌞",
    "Embrace discomfort and grow beyond your limits! 🌠",
    "Believe in the beauty of your dreams and pursue them relentlessly! 🌈",
    "Your mindset determines your success; cultivate a positive one! 🌟",
    "Trust the timing of your life and keep moving forward! ⏰",
    "Embrace challenges as opportunities for greatness! 💪",
    "Stay focused on your goals, even when the path gets tough! 👀",
    "Celebrate your small victories along the way! 🎉",
    "Your hard work and dedication will pay off in due time! 💯",
    "Create a life that inspires you and others! 🌟",
    "Believe in the power of your dreams and pursue them fearlessly! ✨",
    "Keep pushing forward; greatness awaits you! 🚀",
    "Do not underestimate the impact of consistent effort! 💪",
    "Embrace the discomfort of growth and become unstoppable! 🔥",
    "Set your intentions, take action, and watch your dreams unfold! 🌠",
    "Be relentless in your pursuit of greatness! 💥",
    "Embrace the challenges, for they are the stepping stones to success! 🌊",
    "Do not just dream; take action and make it a reality! 💫",
  ];

  const getRandomInspirationText = () => {
    const index = Math.floor(Math.random() * inspirationTexts.length);
    return inspirationTexts[index];
  };

  const [msg, setMsg] = useState(getRandomInspirationText());

  useEffect(() => {
    setMsg(getRandomInspirationText());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listID]);

  return (
    <div style={{ padding: "8px" }}>
      <Header tooltip="Enjoy ❤️❤️❤️" text={msg} fontSize={28} />
    </div>
  );
};

export default Inspiration;

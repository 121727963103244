import moment from "moment-timezone";

export class DateFormat {
  formatDate = (date: Date, withTime: boolean, showDay?: boolean): string => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const inputDate = new Date(date.getTime());
    inputDate.setHours(0, 0, 0, 0);

    if (inputDate.getTime() === today.getTime()) {
      return "Today" + (withTime ? " " + this.formatTime(date) : "");
    } else if (inputDate.getTime() === tomorrow.getTime()) {
      return "Tomorrow" + (withTime ? " " + this.formatTime(date) : "");
    } else if (inputDate.getTime() === yesterday.getTime()) {
      return "Yesterday" + (withTime ? " " + this.formatTime(date) : "");
    }

    // Check if the date is within the next week
    const nextWeek = new Date(today.getTime());
    nextWeek.setDate(nextWeek.getDate() + 7);

    const lastWeek = new Date(today.getTime());
    lastWeek.setDate(today.getDate() - 7);

    if (
      inputDate.getTime() >= lastWeek.getTime() &&
      inputDate.getTime() < today.getTime()
    ) {
      // Show only the full day name
      const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
      if (withTime) {
        return `${dayName} ${this.formatTime(date)}`;
      } else {
        return dayName;
      }
    }

    if (
      inputDate.getTime() >= today.getTime() &&
      inputDate.getTime() <= nextWeek.getTime()
    ) {
      // Show only the full day name
      const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
      if (withTime) {
        return `${dayName} ${this.formatTime(date)}`;
      } else {
        return dayName;
      }
    }

    // If the date is not within the next week, proceed as before
    const showYear = today.getFullYear() !== date.getFullYear();
    let dateStr: string = "";
    dateStr += date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: showYear ? "numeric" : undefined,
    });
    if (withTime) {
      dateStr += " " + this.formatTime(date);
    }
    if (showDay) {
      dateStr += ` (${date.toLocaleDateString("en-US", {
        weekday: "short",
      })})`;
    }
    return dateStr;
  };

  syncDateWithUserTimeZone = (
    userTimeZone: string,
    currentDate: Date
  ): Date => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Check if the provided time zone is the same as the local time zone
    if (localTimeZone === userTimeZone) {
      // If the time zones are the same, return the original date
      return currentDate;
    }

    // Get the time zone objects for both the user and local time zones
    const userTimeZoneMoment = moment.tz.zone(userTimeZone);
    const localTimeZoneMoment = moment.tz.zone(localTimeZone);

    // Check if the time zone objects are valid
    if (!userTimeZoneMoment || !localTimeZoneMoment) {
      console.error("One or more time zones not found.");
      return currentDate; // Fallback to the current date
    }

    // Get the offsets for the user's time zone and the local time zone at the specific time
    const userOffset = userTimeZoneMoment.utcOffset(currentDate.getTime());
    const localOffset = localTimeZoneMoment.utcOffset(currentDate.getTime());

    // Calculate the difference in minutes and adjust the Date object
    const offsetDifference = userOffset - localOffset;
    const adjustedDate = new Date(
      currentDate.getTime() + offsetDifference * 60000
    );

    return adjustedDate;
  };
  formatTime = (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, "0"); // Ensure 2-digit hour
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensure 2-digit minute
    return `${hours}:${minutes}`;
  };

  dateHasPassed = (date: Date, withTime: boolean): boolean => {
    const now = new Date();
    return withTime
      ? date < now
      : date.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0);
  };
}

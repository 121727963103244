import { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { ThemeContext } from "../../../Context/Color";

type SectionHeaderProps = {
  sectionName: string;
  index: number;
  drag_id: string;
};

const SectionHeader = (props: SectionHeaderProps) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Draggable
      key={props.drag_id}
      draggableId={props.drag_id}
      isDragDisabled={true}
      index={props.index}
    >
      {(provided, snapshot): JSX.Element => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key="section-name"
        >
          <div
            style={{
              border: "1px solid black",
              backgroundColor: themeContext?.sectionColor,
              color: themeContext?.sectionTextColor,
              borderRadius: "5px",
              padding: "8px",
              fontSize: "20px",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          >
            {props.sectionName}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default SectionHeader;

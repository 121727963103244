import { useContext, useEffect, useRef, useState } from "react";
import { JSX } from "react/jsx-runtime";
import { ThemeContext } from "../../Context/Color";
import { useNavigate } from "react-router-dom";
import { useData } from "../../Context/Data";
import {
  UserData,
  excludeAllUsers,
  updateUserLastActivity,
  updateUserPlatform,
  updateUserRecCounts,
} from "../../Data/User";

import Loader from "../Loader/Loader";
import "./Style.css"; // Import the CSS file
import { DateFormat } from "../../Util/Date";
import Button from "../../UI/Atoms/Button/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { LogData } from "../../Data/Log";
import BasicTable from "../../UI/Organizm/Table/Table";
import LinearProgress from "@mui/material/LinearProgress";
import StatsCard from "../../UI/Molecules/StatsCard/StatsCard";
import StatsCountTable from "../../UI/Molecules/StatsCountTable/StatsCountTable";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import PopupMenu from "../../UI/Molecules/PopupMenu/PopupMenu";
import Icon from "../../UI/Atoms/Icon/Icon";
import StringTable from "../../UI/Molecules/StringTable/StringTable";

import Calendar from "../../UI/Organizm/Calendar/Calendar";
import { useSnackbar } from "../../Context/SnackbarProvider";
import { Reminder } from "../../Data/Reminder";
import EditBox from "../../UI/Atoms/EditBox/EditBox";

type CountryCounts = {
  [countryName: string]: number;
};

type AdminProps = {
  isAdmin?: boolean;
  userID: string;
};

const AdminDlg = (props: AdminProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);
  const navigate = useNavigate();
  const [totalUsers, setTotalUsers] = useState<UserData[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);
  const [activeUsers, setActiveUsers] = useState<UserData[]>([]);
  const [logs, setLogs] = useState<LogData[]>([]);
  const [loading, setLoading] = useState(true);
  const [findTask, setFindTask] = useState(false);
  const [newUsers, setNewUsers] = useState<UserData[]>([]);

  const [showDatePicker, setShowDatePicker] = useState(false);

  //Initlize a new state variable with date of 1 1 2024
  const [selectedDate, setSelectedDate] = useState(new Date("2024-01-01"));

  const [update, setUpdate] = useState(false);

  const [showAllUsers, setShowAllUsers] = useState(false);

  const [totalUsersCount, setTotalUsersCount] = useState(0);

  const [hoursReturningUsers, setHoursReturningUsers] = useState(24);

  const [dayOneRetention, setDayOneRetention] = useState("");
  const [daySevenRetention, setDaySevenRetention] = useState("");
  const [dayThirtyRetention, setDayThirtyRetention] = useState("");

  const [users24, setUsers24] = useState("");
  const [users72, setUsers72] = useState("");
  const [users168, setUsers168] = useState("");

  const [totalRegsitration, setTotalRegistration] = useState("");

  const [findResult, setFindResult] = useState("");

  const dataProvider = useData();

  const firstRun = useRef(true);

  const taskIdREf = useRef<HTMLInputElement>(null);

  const userIdREf = useRef<HTMLInputElement>(null);

  const [activeTab, setActiveTab] = useState(0);

  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set());

  const dateFormatter = new DateFormat();

  const { addSnackbar } = useSnackbar();

  const viewOptions = [
    {
      label: "Last 24 hours",
      onClick: () => {
        setHoursReturningUsers(24);
      },
      color: themeContext?.textColor!,
      iconComponent: <Icon history={true} />,
    },
    {
      label: "Last 3 days",
      onClick: () => {
        setHoursReturningUsers(72);
      },
      color: themeContext?.textColor!,
      iconComponent: <Icon history={true} />,
    },
    {
      label: "Last 7 days",
      onClick: () => {
        setHoursReturningUsers(168);
      },
      color: themeContext?.textColor!,
      iconComponent: <Icon history={true} />,
    },
  ];

  const onRowSelected = (rowID: string) => {
    // Clone the current selectedUsers Set to make a new one
    const newSelectedUsers = new Set(selectedUsers);

    if (newSelectedUsers.has(rowID)) {
      // If already selected, remove from selectedUsers
      newSelectedUsers.delete(rowID);
    } else {
      // If not selected, add to selectedUsers
      newSelectedUsers.add(rowID);
    }

    // Update the state with the new Set
    setSelectedUsers(newSelectedUsers);
  };

  const timeZoneToCountry = (tz: string, email: string) => {
    if (email === "boloking2323@gmail.com") return "Israel";

    const timeZoneMappings: Record<string, string> = {
      "America/New_York": "USA",
      "America/Los_Angeles": "USA",
      "America/Chicago": "USA",
      "America/Denver": "USA",
      "America/Phoenix": "USA",
      "America/Anchorage": "USA",
      "America/Honolulu": "USA",
      "Asia/Jerusalem": "Israel",
      "Pacific/Auckland": "New Zealand",
      "Europe/London": "United Kingdom",
      "Europe/Paris": "France",
      "Europe/Berlin": "Germany",
      "Europe/Istanbul": "Turkey",
      "Asia/Tokyo": "Japan",
      "Asia/Shanghai": "China",
      "Asia/Dubai": "United Arab Emirates",
      "America/Toronto": "Canada",
      "Europe/Prague": "Czech Republic",
      "Asia/Beirut": "Lebanon",
      "Africa/Cairo": "Egypt",
      "Asia/Nicosia": "Cyprus",
      "Europe/Brussels": "Belgium",
      "Europe/Amsterdam": "Netherlands",
      "Asia/Manila": "Philippines",
      "Asia/Seoul": "South Korea",
      "Asia/Jakarta": "Indonesia",
      "Asia/Karachi": "Pakistan",
      "Africa/Johannesburg": "South Africa",
      "Asia/Kolkata": "India",
      "Europe/Gibraltar": "Gibraltar",
      "Asia/Hebron": "Palestine",
      "Asia/Riyadh": "Saudi Arabia",
      "Europe/Bratislava": "Slovakia",
      "Africa/Algiers": "Algeria",
      "Asia/Amman": "Jordan",
      "Europe/Zurich": "Switzerland",
      "America/Mexico_City": "Mexico",
      "America/Porto_Velho": "Brazil",
      "Asia/Taipei": "Taiwan",
      "Australia/Brisbane": "Australia",
      "Asia/Baghdad": "Iraq",
      "Europe/Rome": "Italy",
      "America/Lima": "Peru",
      "America/Sao_Paulo": "Brazil",
      "Africa/Lubumbashi": "Democratic Republic of the Congo",
      "Africa/Maseru": "Lesotho",
      "Asia/Kathmandu": "Nepal",
      "Europe/Oslo": "Norway", // Oslo mapping
      "Asia/Bangkok": "Thailand", // Bangkok mapping
      "America/Paramaribo": "Suriname", // Paramaribo mapping
      "America/Buenos_Aires": "Argentina", // Buenos Aires mapping
      "Asia/Katmandu": "Napal",
      "Asia/Calcutta": "India",
      "Europe/Madrid": "Spain",
      "Europe/Belgrade": "Serbia",
      "Asia/Singapore": "Singapore",
      "Asia/Dhaka": "Bangladesh",
      "Asia/Saigon": "Vietnam",
      "Europe/Warsaw": "Poland",
      "Australia/Sydney": "Australia",
      "Asia/Phnom_Penh": "Cambodia",
      "Europe/Lisbon": "Portugal",
      "Asia/Makassar": "Indonesia",
      "Asia/Tashkent": "Uzbekistan",
      "Europe/Helsinki": "Finland",
      "Africa/Porto-Novo": "Benin",
      "Europe/Bucharest": "Romania",
      "America/Hermosillo": "Mexico",
      "Europe/Stockholm": "Sweden",
      "Asia/Tehran": "Iran",
      "America/Guayaquil": "Ecuador",
      "America/Guatemala": "Guatemala",
      "America/Argentina/Buenos_Aires": "Argentina",
      "Africa/Accra": "Ghana",
      "Africa/Nairobi": "Kenya",
      "Africa/Tunis": "Tunisia",
      "Europe/Vienna": "Austria",
      "Europe/Moscow": "Russia",
      "Europe/Zagreb": "Croatia",
      "Europe/Tallinn": "Estonia",
      "Africa/Abidjan": "Côte d'Ivoire",
      "Asia/Qyzylorda": "Kazakhstan",
      "America/Port-au-Prince": "Haiti",
      "Asia/Ulaanbaatar": "Mongolia",
      "Africa/Dar_es_Salaam": "Tanzania",
      "Asia/Almaty": "Kazakhstan",
      "Asia/Hong_Kong": "Hong Kong",
      "Pacific/Honolulu": "USA",
      "Asia/Kolkata (IST)": "India",
      // Add more timezone-country mappings here
    };

    // Check if the timezone is in the mappings, if not, return the original timezone
    return timeZoneMappings[tz] || tz;
  };

  const getUserCountry = (email: string, usersDB: UserData[]) => {
    const targetItem = usersDB.find((user) => user.email === email);
    if (targetItem === undefined) return "";

    return timeZoneToCountry(targetItem.timeZone, email);
  };

  const getUserPlatform = (email: string, usersDB: UserData[]) => {
    const targetItem = usersDB.find((user) => user.email === email);
    if (targetItem === undefined) return "";

    return translatePlatform(targetItem.lastPlatform!);
  };

  const translatePlatform = (plat: string) => {
    if (plat === "web") return "🌐 Web";
    else if (plat === "web-mobile") return "📱 Web (Mobile)";
    else if (plat === "web-flutter") return "🐛 Web (Flutter)";
    else if (plat === "mobile-android") return "🤖 Android";
    else if (plat === "mobile-apple") return "🍎 iPhone";

    return plat;
  };

  const isExcludedUser = (email: string, usersDB: UserData[]) => {
    const targetItem = usersDB.find((user) => user.email === email);
    if (targetItem === undefined) return true;

    return false;
  };

  const getLogs = async (usersDB: UserData[]) => {
    if (logs.length > 0) return;
    const start = performance.now(); // Start the timer
    let logs_server = await dataProvider.getLogs(7);
    console.log(logs_server.length);
    setLogs(logs_server);

    const end = performance.now(); // End the timer
    console.log(`Gettings logs took ${(end - start) / 1000} seconds.`);

    logs_server = logs_server.filter(
      (log) =>
        log.user !== "somekh.dave@gmail.com" &&
        log.user !== "david@solidcam.com" &&
        log.user !== "kr@kr.com" &&
        !log.msg.includes("sent to device") &&
        !log.msg.includes("SENDING TO DEVICE") &&
        !log.msg.includes("FOUND 8 DEVICES") &&
        !log.msg.includes("FOUND 1 DEVICES") &&
        !log.msg.includes("PERSON NAME") &&
        !log.msg.includes("ON USER CREATE") &&
        !isExcludedUser(log.user, usersDB) &&
        log.msg !== "STARTING TO LOOP DEVICES" &&
        log.msg !== "FINISHED LOOPING DEVICES" &&
        log.msg !== "COULD NOT FIND ANY DEVICE" &&
        log.msg !==
          'ON TASK CREATE: "☝️ Click the 3 dots above to view completed tasks"' &&
        log.msg !==
          'ON TASK CREATE: "🚩 flag a task to move it to top of the list"' &&
        log.msg !==
          'ON TASK CREATE: "📋 Click on a task to copy it\'s content to the clipboard"' &&
        log.msg !==
          'ON TASK CREATE: "👈🏼 👉🏼Swipe left or right to complete a task"' &&
        log.msg !== '"ON TASK CREATE: ⏰ Click on a task to add reminders"' &&
        log.msg !==
          'ON TASK CREATE: "☝️ Click the 3 dots above to view completed tasks"' &&
        log.msg !==
          'ON TASK CREATE: "📋 Copy and paste a few lines to add a few tasks at once!"' &&
        log.msg !== 'ON TASK CREATE: "⏰ Click on a task to add reminders"' &&
        log.msg !==
          'ON TASK CREATE: "👈 Click on the navigation menu on the top left side to add new lists"' &&
        log.msg !== '"ON TASK CREATE: "📝 Click on a list name to edit it"' &&
        log.msg !== 'ON PROJECT CREATE: "Home"' &&
        log.msg !==
          'ON TASK CREATE: "🤗 To reorder tasks, drag and drop each task to its new position"' &&
        log.msg !== 'ON TASK CREATE: "📝 Click on a list name to edit it"' &&
        log.msg !== 'ON PROJECT CREATE: "Home"' &&
        log.msg !== 'ON TASK CREATE: "🗒️ Click on a task to add description"' &&
        log.msg !== 'ON TASK CREATE: "📱 Install the Mobile Application"' &&
        log.msg !==
          'ON TASK CREATE: "🖱️ Right click on a task for more options"' &&
        log.msg !==
          'ON TASK CREATE: "👈 Click the button on the top left side to add new lists"' &&
        log.msg !==
          'ON TASK CREATE: "⏰ Add a recurring reminder to get email alerts and mobile push notifications"' &&
        !log.msg.includes("PERSON NAME")
    );
    setLogs(logs_server);
  };

  const setRetentionRates = (users: UserData[]) => {
    if (users.length === 0) {
      return; // Return default values if no users
    }

    let retainedDay1 = 0;
    let retainedDay7 = 0;
    let retainedDay30 = 0;

    let registered7DaysAgo = 0;
    let registered30DaysAgo = 0;

    let today = new Date();

    let totalUsersSinceDate = 0;

    for (const user of users) {
      if (user.created) {
        let created = user.created!.toDate();
        const createdTime = user.created.toDate().getTime();
        const lastActivityTime = user.lastActivity
          ? user.lastActivity.toDate().getTime()
          : 0;

        if (
          created > selectedDate &&
          (user.lastPlatform == null || !user.lastPlatform!.includes("flutter"))
        ) {
          totalUsersSinceDate++;

          if (lastActivityTime != null) {
            const daysInApp = Math.floor(
              (lastActivityTime - createdTime) / (1000 * 3600 * 24)
            );

            const daysSinceRegistration = Math.floor(
              (today.getTime() - createdTime) / (1000 * 3600 * 24)
            );

            if (daysInApp >= 1) {
              retainedDay1++;
            } else {
              //zeroRetention++;
            }

            if (daysSinceRegistration >= 7) {
              registered7DaysAgo++;
              if (daysInApp >= 7) {
                retainedDay7++;
              }
            }

            if (daysSinceRegistration >= 30) {
              registered30DaysAgo++;
              if (daysInApp >= 30) {
                retainedDay30++;
              }
            }
          }
        }
      }
    }

    setDayOneRetention(
      retainedDay1.toString() +
        " (" +
        ((retainedDay1 / totalUsersSinceDate) * 100).toFixed(2).toString() +
        "%)"
    );
    setDaySevenRetention(
      retainedDay7.toString() +
        " (" +
        +((retainedDay7 / registered7DaysAgo) * 100).toFixed(2).toString() +
        "%)"
    );
    setDayThirtyRetention(
      retainedDay30.toString() +
        " (" +
        ((retainedDay30 / registered30DaysAgo) * 100).toFixed(2).toString() +
        "%)"
    );

    setTotalRegistration(totalUsersSinceDate.toString());
  };

  const getData = async () => {
    let fetchedUsers = await dataProvider.getUsers();
    fetchedUsers = fetchedUsers.filter((user) => !user.filter);

    setTotalUsers(fetchedUsers);

    setRetentionRates(fetchedUsers);

    setTotalUsersCount(fetchedUsers.length);

    const currentTime = new Date().getTime();

    const retUsers = fetchedUsers.filter((user) => {
      const lastActivityTime = user.lastActivity
        ? user.lastActivity.toDate().getTime()
        : 0;
      if (user.lastActivity && user.created) {
        const difference = Math.floor(
          (currentTime - lastActivityTime) / (1000 * 3600)
        );

        const createdTime = user.created!.toDate().getTime();
        const difference_days = Math.floor(
          (lastActivityTime - createdTime) / (1000 * 3600 * 24)
        ); // Difference in days

        return difference <= 168 && Math.round(difference_days) >= 1;
      }
      return false;
    });

    const newUsers = fetchedUsers.filter((user) => {
      if (user.created) {
        const lastActivityTime = user.lastActivity
          ? user.lastActivity.toDate().getTime()
          : 0;

        const createdTime = user.created.toDate().getTime();
        const difference = Math.floor(
          (currentTime - createdTime) / (1000 * 3600 * 24)
        );

        if (difference <= 7 && lastActivityTime !== 0) {
          return true;
        }
      }
      return false;
    });

    const combinedUsers = [...newUsers, ...retUsers];

    let last24 = 0;
    let last72 = 0;
    let last168 = 0;

    const usersTimeFrame = retUsers.filter((user) => {
      const lastActivityTime = user.lastActivity
        ? user.lastActivity.toDate().getTime()
        : 0;
      if (user.lastActivity && user.created) {
        const difference = Math.floor(
          (currentTime - lastActivityTime) / (1000 * 3600)
        );

        if (difference <= 24) {
          last24++;
          last72++;
          last168++;
        } else if (difference <= 72) {
          last72++;
          last168++;
        } else if (difference <= 168) last168++;

        return difference <= hoursReturningUsers;
      }
      return false;
    });

    setUsers24(last24.toString());
    setUsers72(last72.toString());
    setUsers168(last168.toString());

    const uniqueUsers = combinedUsers.filter(
      (user, index, self) =>
        index === self.findIndex((t) => t.email === user.email)
    );

    // Sort fetchedUsers array in descending order based on lastActivity field
    uniqueUsers.sort((a, b) => {
      const lastActivityA = a.lastActivity
        ? a.lastActivity.toDate().getTime()
        : 0;
      const lastActivityB = b.lastActivity
        ? b.lastActivity.toDate().getTime()
        : 0;
      return lastActivityB - lastActivityA;
    });

    setNewUsers(newUsers);

    setActiveUsers(usersTimeFrame); // Set activeUsers to contain users active in the last 7 days
    setUsers(uniqueUsers); // Set fetchedUsers as the original users
    getLogs(fetchedUsers);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!firstRun.current) {
        // console.log("getting data");
        setUpdate(true);
        await getData();
        setUpdate(false);
      }
      firstRun.current = false;
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursReturningUsers, selectedDate]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        await getData();
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!props.isAdmin) {
    navigate("/list/home");
    return <div></div>;
  }

  if (loading) {
    return (
      <div style={{ width: "100%" }}>
        <Loader />
      </div>
    );
  }

  const getUsersData = (): any[][] => {
    let users_list = showAllUsers ? totalUsers : users;
    return users_list.map((user) => {
      const lastActivityTime = user.lastActivity
        ? user.lastActivity.toDate().getTime()
        : 0;
      const createdTime = user.created!.toDate().getTime();
      const difference = Math.floor(
        (lastActivityTime - createdTime) / (1000 * 3600 * 24)
      ); // Difference in days

      return [
        user.email,
        user.lastActivity
          ? dateFormatter.formatDate(user.lastActivity.toDate(), true, true)
          : "",
        dateFormatter.formatDate(user.created!.toDate(), true, true),

        Math.round(difference), // Fifth parameter: difference between lastActivityDate and createdDate
        user.taskCount,
        user.listCount,
        user.remindersCount,
        user.introduced ? "Yes" : "No",
        timeZoneToCountry(user.timeZone, user.email),
        translatePlatform(user.lastPlatform!),
        user.name,
      ];
    });
  };

  const getLogsData = (): any[][] => {
    return logs.map((log) => [
      log.user,
      getUserCountry(log.user, users),
      getUserPlatform(log.user, users),
      dateFormatter.formatDate(log.time!.toDate(), true, true),
      log.msg,
    ]);
  };

  const onRefresh = async () => {
    setUpdate(true);
    await getData();
    setUpdate(false);
  };

  const getRemindersCount = () => {
    let new_tasks = [...logs];

    new_tasks = new_tasks.filter((log) =>
      log.msg.includes("ON REMINDER NEW,EDIT")
    );

    return new_tasks.length;
  };

  const getTaskEditCount = () => {
    let new_tasks = [...logs];

    new_tasks = new_tasks.filter((log) => log.msg.includes("ON TASK EDIT"));

    return new_tasks.length;
  };

  const getListCreateCount = () => {
    let new_tasks = [...logs];

    new_tasks = new_tasks.filter((log) =>
      log.msg.includes("ON PROJECT CREATE")
    );

    return new_tasks.length;
  };

  const getListEditCount = () => {
    let new_tasks = [...logs];

    new_tasks = new_tasks.filter((log) => log.msg.includes("ON PROJECT EDIT"));

    return new_tasks.length;
  };

  const getTaskCount = () => {
    let new_tasks = [...logs];

    new_tasks = new_tasks.filter((log) => log.msg.includes("ON TASK CREATE"));

    return new_tasks.length;
  };

  const getNewUsersByCountry = () => {
    let countsByCountry: CountryCounts = {}; // Object to hold the count of returning users by country

    // Loop through the array of returning users
    newUsers.forEach((user) => {
      let country = timeZoneToCountry(user.timeZone, user.email);
      if (countsByCountry[country]) {
        // If the country is already in the object, increment the count
        countsByCountry[country]++;
      } else {
        // If the country is not in the object, add it with a count of 1
        countsByCountry[country] = 1;
      }
    });

    return countsByCountry; // Return the counts by country
  };

  const getNewUsersByPlatform = () => {
    let newUse = newUsers; // Assume this returns an array of user objects
    let countsByCountry: CountryCounts = {}; // Object to hold the count of returning users by country

    // Loop through the array of returning users
    newUse.forEach((user) => {
      let platform = translatePlatform(user.lastPlatform!);
      if (countsByCountry[platform!]) {
        // If the country is already in the object, increment the count
        countsByCountry[platform!]++;
      } else {
        // If the country is not in the object, add it with a count of 1
        countsByCountry[platform!] = 1;
      }
    });

    return countsByCountry; // Return the counts by country
  };

  const getAllUsersByPlatform = () => {
    // Assume this returns an array of user objects
    let countsByCountry: CountryCounts = {}; // Object to hold the count of returning users by country

    // Loop through the array of returning users
    totalUsers.forEach((user) => {
      let platform = translatePlatform(user.lastPlatform!);
      if (countsByCountry[platform!]) {
        // If the country is already in the object, increment the count
        countsByCountry[platform!]++;
      } else {
        // If the country is not in the object, add it with a count of 1
        countsByCountry[platform!] = 1;
      }
    });

    return countsByCountry; // Return the counts by country
  };

  const getReturningUsersByPlatform = () => {
    let countsByCountry: CountryCounts = {}; // Object to hold the count of returning users by country

    // Loop through the array of returning users
    activeUsers.forEach((user) => {
      let platform = translatePlatform(user.lastPlatform!);
      if (countsByCountry[platform!]) {
        // If the country is already in the object, increment the count
        countsByCountry[platform!]++;
      } else {
        // If the country is not in the object, add it with a count of 1
        countsByCountry[platform!] = 1;
      }
    });

    return countsByCountry; // Return the counts by country
  };

  const getAllUsersByCountry = () => {
    let countsByCountry: CountryCounts = {}; // Object to hold the count of returning users by country

    // Loop through the array of returning users
    totalUsers.forEach((user) => {
      let country = timeZoneToCountry(user.timeZone, user.email);
      if (country !== undefined) {
        if (countsByCountry[country]) {
          // If the country is already in the object, increment the count
          countsByCountry[country]++;
        } else {
          // If the country is not in the object, add it with a count of 1
          countsByCountry[country] = 1;
        }
      }
    });

    return countsByCountry; // Return the counts by country
  };

  const getReturningUsersByCountry = () => {
    let countsByCountry: CountryCounts = {}; // Object to hold the count of returning users by country

    // Loop through the array of returning users
    activeUsers.forEach((user) => {
      let country = timeZoneToCountry(user.timeZone, user.email);
      if (countsByCountry[country]) {
        // If the country is already in the object, increment the count
        countsByCountry[country]++;
      } else {
        // If the country is not in the object, add it with a count of 1
        countsByCountry[country] = 1;
      }
    });

    return countsByCountry; // Return the counts by country
  };

  const onFindTask = () => {
    setFindTask(!findTask);
  };

  const onFindTaskFinal = () => {
    console.log(taskIdREf.current?.value);
    console.log(userIdREf.current?.value);

    setFindResult("found");
  };

  const onScript = () => {
    let rem: Reminder[] = dataProvider.getAllReminders();

    rem = rem.filter((r) => r.cronJobID !== undefined);
    rem = rem.filter((r) => r.cronJobID !== "");
    rem = rem.filter((r) => r.deleted !== true);
    //  rem = rem.filter((r) => r.done !== true);

    console.log(rem.length);

    let count = 0;

    for (const r of rem) {
      //console.log(r.time);
      //Init a new date object with the time of the reminder (Timestamp firestore)
      let date = new Date(r.time!.toDate().getTime());
      //check if this date has passed
      if (date >= new Date() || r.recurring) {
        count++;
      }
    }

    console.log(count);
    /*let count = 0;

    for (const task of tasks) {
      // console.log(task);
      if (task.done) {
        const rems: Reminder[] = dataProvider.getTaskReminders(task.id!);
        for (const r of rems) {
          //   console.log(r.id);
          // console.log(r.cronJobID);
          count++;
          setReminderDone(props.userID, r.id!);
        }
      }
    }

    console.log(count);*/
  };

  const onExclude = () => {
    const userIds = Array.from(selectedUsers);

    excludeAllUsers(userIds);

    selectedUsers.clear();
    setSelectedUsers(selectedUsers);

    onRefresh();
  };

  const onCopyEmail = () => {
    const userID = Array.from(selectedUsers);

    for (let key in users) {
      if (users.hasOwnProperty(key)) {
        if (users[key].uid === userID[0]) {
          navigator.clipboard.writeText(users[key].email);
          addSnackbar("Copied: " + users[key].email);
        }
        // Do something with 'user'
      }
    }
  };

  const onRow = (rowIndex: number) => {
    let user = users[rowIndex];
    onRowSelected(user.uid);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateUsersLastAcitivity = async () => {
    let ret = totalUsers;

    ret = ret.filter((user) => user.lastActivity === undefined);

    // Use a for...of loop to properly handle async/await
    for (const user of ret) {
      try {
        console.log("updating for: " + user.email);

        const activityDate = await dataProvider.getUserLastActivity(user.uid);
        updateUserLastActivity(activityDate, user.uid);
        // console.log(user.email);
        /* if (user.lastPlatform === "" || user.lastPlatform === undefined) {
          console.log(user.email + " does not have a platform");
          let userPlatform = await dataProvider.getUserLastActivity(user.uid);
          console.log(userPlatform);
          updateUserPlatform(userPlatform.toString(), user.uid);
          //console.log("updated platform for " + user.email);
        }*/
      } catch (error) {
        // Handle any errors that occur during the platform check
        console.error(`Error updating platform for ${user.email}: ${error}`);
        // Optionally, you can perform some error handling actions here,
        // like retrying the operation, logging the error to a monitoring service, etc.
      }
    }

    //  console.log(i + " users with platform");
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateUsersCounts = async () => {
    let ret = totalUsers;

    // Use a for...of loop to properly handle async/await
    for (const user of ret) {
      try {
        console.log("updating for: " + user.email);

        const counts = await dataProvider.getUserCounts(user.uid);

        updateUserRecCounts(
          counts.tasksSize,
          counts.listsSize,
          counts.remindersSize,
          user.uid
        );
        // updateUserLastActivity(activityDate, user.uid);
      } catch (error) {
        console.error(`Error updating platform for ${user.email}: ${error}`);
      }
    }
  };

  const updateUsersPlatform = async () => {
    let ret = users;

    ret = ret.filter(
      (user) => user.lastPlatform === "" || user.lastPlatform === undefined
    );

    // Use a for...of loop to properly handle async/await
    for (const user of ret) {
      try {
        // console.log(user.email);
        if (user.lastPlatform === "" || user.lastPlatform === undefined) {
          console.log(user.email + " does not have a platform");
          let userPlatform = await dataProvider.checkUserPlatform(user.uid);
          console.log(userPlatform);
          updateUserPlatform(userPlatform.toString(), user.uid);
          //console.log("updated platform for " + user.email);
        }
      } catch (error) {
        // Handle any errors that occur during the platform check
        console.error(`Error updating platform for ${user.email}: ${error}`);
        // Optionally, you can perform some error handling actions here,
        // like retrying the operation, logging the error to a monitoring service, etc.
      }
    }

    //  console.log(i + " users with platform");
  };

  const onShowAll = () => {
    setShowAllUsers(!showAllUsers);
  };

  const getButtonText = () => {
    if (hoursReturningUsers === 24) {
      return "Returning Users (Last 24 hours)";
    } else if (hoursReturningUsers === 72) {
      return "Returning Users (Last 3 days)";
    }

    return "Returning Users (Last 7 days)";
  };

  const onChangeDate = () => {
    if (showDatePicker) return;
    setShowDatePicker(true);
  };

  //const ret = getReturningUsers();

  updateUsersPlatform();
  //updateUsersCounts();
  //updateUsersLastAcitivity();

  return (
    <div style={{ maxWidth: "100%", color: themeContext?.textColor }}>
      {update && <LinearProgress />}
      <Tabs
        textColor={themeContext?.isDarkTheme ? "inherit" : "primary"}
        value={activeTab}
        onChange={() => {
          if (activeTab === 0) setActiveTab(1);
          else setActiveTab(0);
        }}
        aria-label="basic tabs example"
      >
        <Tab label={"Users"} />
        <Tab label={"Logs"} />
      </Tabs>
      {activeTab === 0 && (
        <div
          style={{
            marginTop: "8px",
            marginBottom: "8px",
            marginLeft: "8px",
            marginRight: "8px",
            fontSize: "28px",
            border: "1px solid black",
            borderRadius: "10px",
            padding: "8px",
            backgroundColor: "white",
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={showAllUsers} onChange={onShowAll} />}
            label={
              <Typography
                style={{
                  fontFamily: "Calibri",
                  fontSize: "16px",
                }}
              >
                Show All Users
              </Typography>
            }
            // Replace with the label you want to use
          />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              color: "black",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <PopupMenu
                  iconComponent={<Icon fontSize={20} history={true} />}
                  textColor={themeContext?.listPopupTextColor}
                  textSize={20}
                  marginTop="6px"
                  checkBoxMenu={false}
                  items={viewOptions}
                  displayBelowParent={true}
                  keepOpenAfterClick={false}
                  buttonFontSize="20px"
                  buttonLabel={getButtonText()}
                  backgroundColor={themeContext?.listPopupColor!}
                  buttonBackgroundColor={"transparent"}
                  buttonColor={themeContext?.textColor!}
                />
              </div>
              <div>{activeUsers.length}</div>
            </div>
            <div style={{ marginTop: "8px" }}>
              <StatsCard
                dontAnimate={true}
                name={"New Users (Last 7 Days)"}
                count={newUsers.length}
              />
            </div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div onClick={onChangeDate} style={{ cursor: "pointer" }}>
              {showDatePicker && (
                <div style={{ fontSize: "16px" }}>
                  <Calendar
                    allowPastDates={true}
                    currentDate={selectedDate}
                    isMobile={false}
                    onDateSelected={function (day: Date): void {
                      setShowDatePicker(false);
                      setSelectedDate(day);
                    }}
                    preventDefaultMouseDown={true}
                  />
                </div>
              )}
              {!showDatePicker && (
                <StringTable
                  data={[
                    ["Users", totalRegsitration],
                    ["Day 1", dayOneRetention],
                    ["Day 7", daySevenRetention],
                    ["Day 30", dayThirtyRetention],
                  ]}
                  title={
                    "Retention Rates Since: " +
                    dateFormatter.formatDate(selectedDate, false, false)
                  }
                />
              )}
            </div>
            <div style={{ width: "10px" }}></div>
            <StringTable
              data={[
                ["Last 24 hours", users24],
                ["Last 3 days", users72],
                ["Last 7 days", users168],
              ]}
              title={"Returning Users"}
            />
            <div style={{ width: "10px" }}></div>
            <StatsCountTable
              title="Returning users by country"
              data={getReturningUsersByCountry()}
            />
            <div style={{ width: "10px" }}></div>
            <StatsCountTable
              title="Returning users by platform"
              data={getReturningUsersByPlatform()}
            />
            <div style={{ width: "10px" }}></div>
            <StatsCountTable
              title="New users by country"
              data={getNewUsersByCountry()}
            />
            <div style={{ width: "10px" }}></div>
            <StatsCountTable
              title="New users by platform"
              data={getNewUsersByPlatform()}
            />
          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div
          style={{
            marginTop: "8px",
            marginBottom: "8px",
            marginLeft: "8px",
            marginRight: "8px",
            fontSize: "28px",
            border: "1px solid black",
            borderRadius: "10px",
            padding: "8px",
            backgroundColor: "white",
          }}
        >
          <div style={{ marginBottom: "16px", color: "black" }}>
            Last 7 Days
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <StatsCard name={"Total users"} count={totalUsersCount} />
              <StatsCard name={"Tasks created"} count={getTaskCount()} />

              <StatsCard name={"Tasks edited"} count={getTaskEditCount()} />

              <StatsCard
                name={"Reminders created"}
                count={getRemindersCount()}
              />

              <StatsCard name={"Lists created"} count={getListCreateCount()} />

              <StatsCard name={"Lists edited"} count={getListEditCount()} />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <StatsCountTable
                title="Country Breakdown"
                data={getAllUsersByCountry()}
              />
              <div style={{ width: "10px" }}></div>
              <StatsCountTable
                title="Platform Breakdown"
                data={getAllUsersByPlatform()}
              />
            </div>
          </div>
        </div>
      )}

      {activeTab === 1 && (
        <BasicTable
          selection={false}
          headers={["User", "Country", "Platform", "Time", "Msg"]}
          data={getLogsData()} // Define your data as an array of arrays
        />
      )}

      <div style={{ display: "flex", marginLeft: "10px" }}>
        <Button
          width={"100px"}
          centerText={true}
          label="Run Script"
          id="btn_script"
          iconComponent={<Icon script={true} />}
          textButton={false}
          onClick={onScript}
          marginBottom={8}
          marginRight={8}
        />
        <Button
          width={"100px"}
          centerText={true}
          label="Find Task"
          id="btn_find_task"
          iconComponent={<Icon search={true} />}
          textButton={false}
          onClick={onFindTask}
          marginBottom={8}
          marginRight={8}
        />
        {selectedUsers.size > 0 && (
          <Button
            width={"100px"}
            centerText={true}
            label="Exclude"
            id="btn_exclude"
            iconComponent={<Icon hideIcon={true} />}
            textButton={false}
            onClick={onExclude}
            marginBottom={8}
            marginRight={8}
          />
        )}

        {selectedUsers.size === 1 && (
          <Button
            width={"100px"}
            centerText={true}
            label="Copy Email"
            id="btn_copy"
            iconComponent={<Icon copyIcon={true} />}
            textButton={false}
            onClick={onCopyEmail}
            marginBottom={8}
          />
        )}
      </div>
      {findTask && (
        <div
          style={{ display: "flex", marginLeft: "10px", marginBottom: "8px" }}
        >
          <EditBox
            type="text"
            placeholder="Task ID"
            id="task_id"
            focus={true}
            EditRef={taskIdREf}
          />
          <div style={{ width: "10px" }}></div>
          <EditBox
            type="text"
            placeholder="User ID"
            id="user_id"
            focus={false}
            EditRef={userIdREf}
          />
          <Button
            id="find_task_final"
            label="Find"
            boldText={true}
            textButton={true}
            onClick={onFindTaskFinal}
          />
        </div>
      )}
      {findResult !== "" && findTask && (
        <div
          style={{
            display: "flex",
            marginLeft: "10px",
            marginBottom: "8px",
            color: "red",
            fontSize: "18px",
          }}
        >
          {findResult}
        </div>
      )}
      {activeTab === 0 && (
        <BasicTable
          onRowSelected={onRow}
          selection={true}
          headers={[
            "Email",
            "Last Seen",
            "Joined On",
            "Days in App",
            "Tasks",
            "Lists",
            "Reminders",
            "Intro",
            "Country",
            "Platform",
            "Name",
          ]}
          data={getUsersData()} // Define your data as an array of arrays
        />
      )}
    </div>
  );
};

export default AdminDlg;

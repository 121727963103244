import React, { useContext, useEffect, useState } from "react";
import "./Style.css";
import { ThemeContext } from "../../../Context/Color";
import Platform from "../../../Util/Platform";

import Tooltip from "@mui/material/Tooltip";

type ButtonProps = {
  id: string;
  actionTip?: string;
  actionTipPosition?:
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
  checkbox?: boolean;
  marginBforeCheckBox?: string;
  checkBoxRight?: boolean;
  padding?: string;
  iconPaddingTop?: string;
  checkBoxRightChecked?: boolean;
  iconComponent?: React.ReactNode;
  label: string;
  marginAfterIcon?: string;
  marginRight?: number;
  marginBottom?: number;
  textButton: boolean;
  backgroundColor?: string;
  textButtonColor?: string;
  borderStyle?: string;
  width?: string;
  centerText?: boolean;
  boldText?: boolean;
  fontSize?: string; // Optional fontSize prop
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
};

const Button: React.FC<ButtonProps> = ({
  id,
  label,
  onClick,
  checkbox,
  padding,
  iconPaddingTop,
  centerText,
  marginBforeCheckBox,
  textButton,
  iconComponent,
  marginBottom,
  textButtonColor,
  checkBoxRight,
  checkBoxRightChecked,
  boldText,
  backgroundColor,
  marginAfterIcon,
  marginRight,
  fontSize,
  width,
  onMouseDown,
  actionTip,
  borderStyle,
  actionTipPosition,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [isClicked, setIsClicked] = useState(false);

  const [isCheckBoxChecked, setIsCheckBoxChecked] =
    useState(checkBoxRightChecked);

  const themeContext = useContext(ThemeContext);

  const { isMobile } = Platform();

  const onBtnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsHovered(false);
    onClick(e);
    if (checkBoxRight) setIsCheckBoxChecked(!isCheckBoxChecked);
  };

  useEffect(() => {
    setIsCheckBoxChecked(checkBoxRightChecked);
  }, [checkBoxRightChecked]);

  const justifyContent = centerText
    ? "center"
    : checkBoxRight
    ? "space-between"
    : "";

  return (
    <Tooltip
      enterDelay={1000}
      title={actionTip}
      arrow
      placement={actionTipPosition || "top"}
    >
      <div
        className="button"
        onClick={!checkbox ? onBtnClick : () => {}}
        style={{
          backgroundColor: !textButton
            ? backgroundColor
              ? backgroundColor
              : themeContext?.buttonTheme.background
            : "",
          opacity: !checkbox && isHovered && !isMobile ? 0.5 : 1,
          padding: padding || "6px",

          borderRadius: textButton ? "" : "10px",
          marginRight: marginRight && `${marginRight}px`,
          marginBottom: marginBottom && `${marginBottom}px`,
          border: checkbox ? "" : borderStyle || "",
          fontWeight: boldText ? "bold" : "",
          display: "flex",
          justifyContent: justifyContent,
          alignItems: !checkbox ? "center" : "",

          color: textButtonColor
            ? textButtonColor
            : textButton
            ? themeContext?.textColor
            : themeContext?.buttonTheme.text,
          fontSize: fontSize,
          width: width, // using the fontSize prop
        }}
        id={id}
        onMouseDown={onMouseDown}
        onMouseEnter={() => {
          if (!checkbox) setIsHovered(true);
        }}
        onMouseLeave={() => {
          if (!checkbox) setIsHovered(false);
        }}
      >
        <div style={{ display: "flex" }}>
          {checkbox && (
            <div
              style={{
                color: isClicked ? "green" : isHovered ? "blue" : "black",
                border: isClicked
                  ? "2px solid green"
                  : isHovered
                  ? "2px solid blue"
                  : themeContext?.checkBoxBorder,
                backgroundColor: isClicked
                  ? "green"
                  : isHovered
                  ? "blue"
                  : themeContext?.checkBoxBackground,
              }}
              onClick={(e) => {
                setIsClicked(true);
                onBtnClick(e);
              }}
              onMouseEnter={() => {
                if (checkbox) setIsHovered(true);
              }}
              onMouseLeave={() => {
                if (checkbox) setIsHovered(false);
              }}
              className={"checkbox"}
            >
              {(isHovered || isClicked) && iconComponent}
            </div>
          )}
          {!checkbox && iconComponent && (
            <div
              style={{
                marginRight: marginAfterIcon || "6px",

                paddingTop: iconPaddingTop || "",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="icon"
            >
              {iconComponent}
            </div>
          )}{" "}
          {label}
        </div>
        {checkBoxRight && (
          <div style={{ marginLeft: marginBforeCheckBox || "" }}>
            {" "}
            <input
              style={{ transform: "scale(1.3)", cursor: "pointer" }}
              type="checkbox"
              checked={isCheckBoxChecked}
              onChange={(e) => {
                e.stopPropagation();
              }}
            />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default Button;

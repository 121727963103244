import React, { useEffect, useState, ReactNode } from "react";
import { useContext } from "react";
import { UserData, createUser } from "../Data/User";

import { auth } from "../firebase";

import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

const AuthContext = React.createContext({
  register: (username: string, password: string): Promise<any> => {
    return Promise.resolve(42);
  },
  authLogin: (username: string, password: string): Promise<any> => {
    return Promise.resolve(42);
  },
  authLogout: () => {},
  authLoginGoogle: (): Promise<any> => {
    return Promise.resolve(42);
  },
  authLoginApple: (): Promise<any> => {
    return Promise.resolve(42);
  },

  authGetUID: (): string => {
    return "";
  },
  getUserPicture: (): string | null => "",
  getUserName: (): string => {
    return "";
  },
  authResetPass: (username: string): Promise<any> => {
    return Promise.resolve(42);
  },
  login: false,
  userImage: "",
  userName: "",
  displayName: "",
  authFinished: false,
});

export function useAuth() {
  return useContext(AuthContext);
}

function authLogin(username: string, password: string): Promise<any> {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        resolve("good");
      })
      .catch((error) => {
        reject(formatError(error));
      });
  });
}
const getUserName = (): string => {
  // Function body goes here
  // You can return a string value
  if (auth.currentUser == null) return "";

  return auth.currentUser.email!;
};

function getUserPicture(): string | null {
  return auth.currentUser?.photoURL || null;
}

function register(username: string, password: string): Promise<any> {
  let userid = "";

  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        if (auth.currentUser?.uid) userid = auth.currentUser?.uid;

        let newuser: UserData = {
          uid: userid,
          email: username,
          dailyAgendaTime: "09:00",
          sendDailyAgenda: true,
          sendAgendaOnWeekend: false,
          agendaCronID: "",
          name: "",
          introduced: false,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          lastTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          filter: false,
          admin: false,
          firstDayOfWeek: 2,
          lastDayOfWeek: 6,
        };
        newuser["uid"] = userid;
        newuser["email"] = username;
        createUser(newuser, userid);
        resolve(newuser);
      })
      .catch((error) => {
        reject(formatError(error));
      });
  });
}

interface FirebaseError extends Error {
  code: string;
}

export function formatError(error: FirebaseError): string {
  switch (error.code) {
    case "auth/invalid-email":
      return "😕 Invalid email address.";
    case "auth/weak-password":
      return "🥴 Don't be lazy! Password should be at least 6 characters.";
    case "auth/email-already-in-use":
      return "👇 User already exists. Click 'I already have an account' to login";
    case "auth/wrong-password":
      return "😕 Invalid password.";
    case "auth/missing-password":
      return "😕 Please insert a password";
    case "auth/user-not-found":
      return "🤔 Email not found. Are you a new user?";
    default:
      return error.message;
  }
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [login, setLogin] = useState(false);
  const [userName] = useState("");
  const [displayName] = useState("");
  const [authFinished, setAuthFinished] = useState(false);
  const [userImage] = useState("");

  function authLogout() {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  const authGetUID = (): string => {
    if (auth.currentUser?.uid != null) return auth.currentUser?.uid;

    return "";
  };

  const authResetPass = (username: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(auth, username)
        .then(() => {
          resolve("good");
        })
        .catch((error) => {
          reject(formatError(error));
        });
    });
  };

  const authLoginApple = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const provider = new OAuthProvider("apple.com");

      try {
        const result = await signInWithPopup(auth, provider);

        // The signed-in user info.
        const user = result.user;

        // Get the Access Token
        const token = await user.getIdToken();

        resolve({ token, user });
      } catch (error) {
        // Handle Errors here.
        reject(error);
      }
    });
  };

  const authLoginGoogle = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      const provider = new GoogleAuthProvider();

      try {
        const result = await signInWithPopup(auth, provider);

        // This gives you a Google Access Token.
        // You can use it to access the Google API.
        // The signed-in user info.
        const user = result.user;

        // Get the Access Token
        const token = await user.getIdToken();

        resolve({ token, user });
      } catch (error) {
        // Handle Errors here.
        reject(error);
      }
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (auth.currentUser != null) {
        setAuthFinished(true);
        setLogin(true);
      } else {
        setAuthFinished(true);
        setLogin(false);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        register,
        authLoginApple,
        authLoginGoogle,
        getUserPicture,
        authResetPass,
        authGetUID,
        authLogin,
        getUserName,
        authLogout,
        login,
        userName,
        displayName,
        authFinished,
        userImage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

import { useContext, useEffect, useMemo, useState } from "react";
import "./Style.css";
import { useAuth } from "../../Context/Auth";
import { useData } from "../../Context/Data";
import { Project } from "../../Data/Project";
import Spinner from "../../UI/Atoms/Spinner/Spinner";
import List from "../List/List";
import { Task } from "../../Data/Task";
import { LocalStorage } from "../../Util/localStorage";
import { ThemeContext } from "../../Context/Color";

import { TaskCounts } from "../../Util/Count";

import AppHeader from "../../UI/Organizm/Header/Header";

import User from "../User/User";
import Sidebar from "../../UI/Organizm/Sidebar/Sidebar";
import { useOverlay } from "../../Context/OverlayProvider";

import Platform from "../../Util/Platform";
import { isAndroid, isiPhone, promoteMobileApp } from "../../Util/Platform";

import IntroDlg from "../IntroPage/IntroPage";
import { useSnackbar } from "../../Context/SnackbarProvider";
import { useNavigate } from "react-router-dom";
import WarnDlg from "../../UI/Organizm/WarnDlg/WarnDlg";
import Icon from "../../UI/Atoms/Icon/Icon";
import {
  cancelDailyAgenda,
  updateLastTimeZone,
  updateTimeZone,
} from "../../Data/User";
import AdminDlg from "../Admin/Admin";

type NavigationProps = {
  isAdminMode: boolean;
};

const Navigation = (props: NavigationProps): JSX.Element => {
  const taskCounts = TaskCounts.instance;
  const auth = useAuth();
  const dataProvider = useData();

  const navigate = useNavigate();

  const storage = new LocalStorage();

  const [listData, setListData] = useState<Project[]>([]);
  const [dataReady, setDataReady] = useState(false);
  const [tasksData, setTasksData] = useState<Task[]>([]);
  const [completedTasksData, setCompletedTasksData] = useState<Task[]>([]);
  const { addOverlay, removeOverlay } = useOverlay();
  const [done, setDone] = useState(false);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const { addSnackbar } = useSnackbar();

  const [showSideBar, setShowSideBar] = useState(
    storage.getValue("sidebar") !== "0"
  );

  const local_storage = new LocalStorage();

  const { isMobileResolution, isMobile } = Platform();
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    if (dataReady) {
      if (dataProvider.getCancelDailyAgenda() === true) {
        dataProvider.setCancelDailyAgenda(false);
        cancelDailyAgenda(auth.authGetUID());
        addSnackbar("Daily agenda has been cancelled.");
      }
    }
    if (dataReady && promoteMobileApp()) {
      addSnackbar(
        "🚀 KEEPLIST IS BETTER ON THE MOBILE APP!",
        15,
        "DOWNLOAD",
        onDownloadApp,
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReady]);

  const onDownloadApp = () => {
    let downloadPageURL = "";

    if (isAndroid())
      downloadPageURL =
        "https://play.google.com/store/apps/details?id=com.keeplist";
    else if (isiPhone())
      downloadPageURL = "https://apps.apple.com/sa/app/keeplist/id1626699174";

    window.open(downloadPageURL, "_blank");
  };

  useEffect(() => {
    if (!isMobileResolution && mobileDrawer) setShowSideBar(true);
    if (!isMobileResolution) setMobileDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileResolution]);

  useEffect(() => {
    setDataReady(dataProvider.isDataReady());
    if (dataProvider.isDataReady()) {
      const delay = 1500; // 3 seconds in milliseconds

      let currentTimeZone: string =
        Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (dataProvider.getUser()?.timeZone === "") {
        updateTimeZone(currentTimeZone, auth.authGetUID());

        return;
      }

      if (currentTimeZone === dataProvider.getUser()?.lastTimeZone) return;

      updateLastTimeZone(currentTimeZone, auth.authGetUID());

      setTimeout(() => {
        addOverlay(
          <WarnDlg
            onCancel={() => {
              removeOverlay();
            }}
            onApprove={() => {
              updateTimeZone(currentTimeZone, auth.authGetUID());
              removeOverlay();
            }}
            isMobile={isMobile}
            icon={<Icon location={true} />}
            applyBtnRed={true}
            title="Update reminders? Time zone has changed."
            subTitle={`${currentTimeZone} is the new time zone.`}
            cancelBtnText="NO"
            approveBtnText="YES, UPDATE TIME ZONE"
          />,
          {
            closeOnClickOutside: true,
            centerX: true,

            width: isMobile ? "" : "",
            marginTop: 100,
          },
          true
        );
        // This code will run after the 3-second delay
      }, delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider.isDataReady]);

  useEffect(() => {
    storage.setValue("sidebar", showSideBar ? "1" : "0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSideBar]);

  useMemo(() => {
    setListData(dataProvider.getLists());
    setTasksData(dataProvider.getTasks());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider.getLists, dataProvider.getTasks]);

  const getProjectObject = (): Project => {
    return dataProvider.getActiveProjectObject()!;
  };

  const getTheme = (): number => {
    let val = local_storage.getValue("THEME");
    if (!val) return 0;

    return Number(val);
  };

  const onShowUser = () => {
    addOverlay(
      <User
        userTimeZone={dataProvider.getUser()?.timeZone!}
        userID={auth.authGetUID()}
        isMobile={isMobileResolution}
        picture={auth.getUserPicture()}
        desktopNotifications={dataProvider.getUser()?.desktopNotifications!}
        pushNotifications={dataProvider.getUser()?.pushNotifications!}
        emailNotifications={dataProvider.getUser()?.emailNotifications!}
        remindersEmail={dataProvider.getUser()?.remindersEmail!}
        firstDayOfWeek={dataProvider.getUser()?.firstDayOfWeek!}
        lastDayOfWeek={dataProvider.getUser()?.lastDayOfWeek!}
        theme={getTheme()}
        name={dataProvider.getUser()?.name!}
        email={dataProvider.getUser()?.email!}
        isAdmin={dataProvider.getUser()?.admin}
        dailyAgendaTime={dataProvider.getUser()?.dailyAgendaTime!}
        sendAgendaOnWeekend={dataProvider.getUser()?.sendAgendaOnWeekend!}
        sendDailyAgenda={dataProvider.getUser()?.sendDailyAgenda!}
      />,
      { closeOnClickOutside: true, centerX: true, marginTop: 8 }
    );
  };

  const onDrawer = () => {
    if (!mobileDrawer) setShowSideBar(true);

    setMobileDrawer(!mobileDrawer);
  };

  const onProject = () => {
    setMobileDrawer(false);
  };

  const goHome = () => {
    navigate(`/list/${dataProvider.getHomeProjectID()}`);
  };

  const onShowCompleted = (show: boolean) => {
    setDone(show);
    if (show) {
      setCompletedTasksData(dataProvider.getCompletedTasks());
    }
  };

  const getCountListID = () => {
    if (dataProvider.isTodayView()) {
      return "today";
    } else if (dataProvider.isUpcomingView()) {
      return "upcoming";
    }
    return dataProvider.getActiveProject().toString();
  };

  return dataReady && dataProvider.getUser()?.introduced ? (
    <div
      style={{ backgroundColor: themeContext?.headerBackground }}
      className="nav"
    >
      <AppHeader
        sidebarVisible={showSideBar}
        isAdminBoard={props.isAdminMode}
        onGoHome={goHome}
        isAdmin={dataProvider.getUser()?.admin}
        isMobileDevice={isMobile}
        userPicture={auth.getUserPicture()}
        isDrawerOpended={mobileDrawer}
        isMobile={isMobileResolution}
        onHideSideBar={() => {
          setMobileDrawer(false);

          setShowSideBar(!showSideBar);
        }}
        userID={auth.authGetUID()}
        onDrawer={onDrawer}
        onUserClick={onShowUser}
      />

      <div className="nav_data">
        {!props.isAdminMode && (
          <Sidebar
            isAdmin={dataProvider.getUser()?.admin!}
            onListCreate={() => {
              setMobileDrawer(false);
            }}
            hiddenMode={isMobileResolution ? !mobileDrawer : !showSideBar}
            userID={auth.authGetUID()}
            isMobile={isMobileResolution}
            onUserClick={onShowUser}
            onProjectClick={onProject}
            projects={listData}
            userPicture={auth.getUserPicture()}
          />
        )}

        {props.isAdminMode && (
          <AdminDlg
            userID={dataProvider.getUser()?.uid!}
            isAdmin={dataProvider.getUser()?.admin}
          />
        )}

        {!props.isAdminMode && (
          <List
            userTimeZone={dataProvider.getUser()?.timeZone!}
            show={!mobileDrawer}
            sidebarVisible={showSideBar}
            todayView={dataProvider.isTodayView()}
            upcomingView={dataProvider.isUpcomingView()}
            activeTask={dataProvider.getActiveTask().toString()}
            userID={auth.authGetUID()}
            onShowCompleted={onShowCompleted}
            isMobile={isMobileResolution}
            listObject={getProjectObject()}
            tasks={!done ? tasksData : completedTasksData}
            listID={dataProvider.getActiveProject().toString()}
            listCount={taskCounts.getCount(getCountListID())}
          />
        )}
      </div>
    </div>
  ) : dataReady && !dataProvider.getUser()?.introduced ? (
    <IntroDlg
      isMobile={isMobileResolution}
      userID={auth.authGetUID()}
      userEmail={auth.getUserName()}
    />
  ) : (
    <Spinner centered={true}></Spinner>
  );
};

export default Navigation;

import React, { useContext, useState } from "react";
import "./Style.css";

import { ThemeContext } from "../../../Context/Color";
import { useData } from "../../../Context/Data";
import { useNavigate } from "react-router-dom";
interface ProjectRowProps {
  projectID: String;
  onProjectClick: () => void; // type changed from void to function
  projectName: String;
  showCount: boolean;
  count: number;
  isHomeProject: boolean;
  isUpcomingProject: boolean;
  isTodayProject: boolean;
  icon: React.ReactNode;
}

const ProjectRow: React.FC<ProjectRowProps> = ({
  projectID,
  count,
  projectName,
  onProjectClick,
  icon,
  showCount,
  isHomeProject,
  isUpcomingProject,
  isTodayProject,
}) => {
  const themeContext = useContext(ThemeContext);

  const dataProvider = useData();

  const navigate = useNavigate();

  if (!themeContext) {
    throw new Error("ThemeContext not provided from a ThemeProvider component");
  }

  const getItemStyle = (
    isHovered: boolean,
    isActive: boolean,
    hoverColor: string,
    isUpcomingProject: boolean
  ): React.CSSProperties => ({
    background: isHovered ? hoverColor : "",
    color: isActive ? themeContext.activeItemColor : "",
    cursor: "pointer",
    marginBottom: isUpcomingProject ? "20px" : "0px",
  });

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (isTodayProject) navigate(`/list/today`);
    else if (isUpcomingProject) navigate(`/list/upcoming`);
    else navigate(`/list/${projectID}`); // Add this line
    onProjectClick();
  };

  const isProjectActive = () => {
    if (isTodayProject) {
      return dataProvider.isTodayView();
    } else if (isUpcomingProject) {
      return dataProvider.isUpcomingView();
    }

    return dataProvider.getActiveProject() === projectID;
  };

  return (
    <>
      <div
        style={getItemStyle(
          isHovered,
          isProjectActive(),
          themeContext.taskHoverColor,
          isUpcomingProject
        )}
        className="project"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <div className="project_icon">{icon}</div>{" "}
        <div className="project_name_row">
          <div className="project_name">{projectName}</div>
          {showCount && <div className="project_count">{count}</div>}
        </div>
      </div>
    </>
  );
};

export default ProjectRow;

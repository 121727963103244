import React, { useContext } from "react";
import { Reminder, formatReminderString } from "../../../Data/Reminder";
import Icon from "../../Atoms/Icon/Icon";
import { ThemeContext } from "../../../Context/Color";

type RemindersRowProps = {
  reminders: Reminder[];
  userTimeZone: string;
};

const RemindersRow = (props: RemindersRowProps) => {
  const themeContext = useContext(ThemeContext);

  let reminders = props.reminders;

  reminders.sort(function (a, b) {
    return a.time.toMillis() - b.time.toMillis();
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontSize: "16px",
        color: themeContext?.textColor,
        marginTop: "6px",
      }}
    >
      {reminders.map((reminder, index) => (
        <div style={{ display: "flex" }} key={reminder.id}>
          <Icon alarmIcon={true} />
          <div style={{ marginLeft: "6px" }} key={index}>
            {formatReminderString(reminder, true, props.userTimeZone)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RemindersRow;

import React, { useEffect, useRef, useState } from "react";
import "./Style.css";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { Task, flagTask } from "../../../Data/Task";
import TaskItem from "../../Molecules/TaskItem/TaskItem";
import { useData } from "../../../Context/Data";
import { useAuth } from "../../../Context/Auth";
import { updateProjectOrder } from "../../../Data/Project";
import closeNewTask from "../../../Util/closeNewTask";

import Platform from "../../../Util/Platform";
import SectionHeader from "../../Molecules/SectionHeader/SectionHeader";
import { DateFormat } from "../../../Util/Date";
import { Timestamp } from "firebase/firestore";
import { updateProjectAndTask } from "../../../Data/Batch";

// fake data generator

interface Section {
  number: number;
  text: string;
}

type SortableProps = {
  data: Task[];
  onTaskCompleted: (done: boolean, taskID: string) => void;
  onTaskClick: (taskID: string) => void;
  onMoveToTop: (taskID: string) => void;
  showReminders: boolean;
  showDescription: boolean;
  listID: string;
  isMobile: boolean;
  completedMode: boolean;
  userTimeZone: string;
  todayView: boolean;
  upcomingView: boolean;
  listRef: React.RefObject<HTMLDivElement>;
};

const List = (props: SortableProps) => {
  const [data, setData] = useState(props.data);
  const datesRef = useRef<Date[]>([]);
  const futureTasks = useRef<string[]>([]);

  const [sections, setSections] = useState<Section[]>([]);

  const dateFormatter = new DateFormat();

  const { isMobile } = Platform();

  const listref = props.listRef;

  const dataProvider = useData();
  const auth = useAuth();

  const reorder = (
    list: Task[],
    startIndex: number,
    endIndex: number
  ): Task[] => {
    // Adjust start and end indices to account for section headers
    const adjustedStartIndex =
      startIndex -
      sectionAdjustment(startIndex, startIndex, endIndex, sections, false);
    const adjustedEndIndex =
      endIndex -
      sectionAdjustment(endIndex, startIndex, endIndex, sections, true);

    const result = Array.from(list);
    const [removed] = result.splice(adjustedStartIndex, 1);

    if (props.todayView || props.upcomingView) {
      let newDueDate = getNewDueDate(startIndex, endIndex);
      updateSectionsCalculation(removed, newDueDate);
    }

    result.splice(adjustedEndIndex, 0, removed);

    return result;
  };
  const updateSectionsCalculation = (task: Task, dueDate: Date) => {
    // Make a copy of data to work with
    let recs = [...data];

    // Find the index of the task with the same ID
    const index = recs.findIndex((t) => t.id === task.id);

    if (index === -1) return; // If no task is found, return without doing anything

    // Create a shallow copy of the task and update the dueDate
    let taskRec: Task = { ...recs[index] };
    taskRec.dueDate = Timestamp.fromDate(dueDate); // Assume Timestamp.fromDate() correctly handles Date objects

    // Update the array with the new task record
    recs[index] = taskRec;

    // Sort the tasks by 'dueDate' in ascending order after the update
    recs.sort((a, b) => {
      return a.dueDate!.toDate().getTime() - b.dueDate!.toDate().getTime();
    });

    setSections(calculateSections(recs)); // Update the sections based on the updated data

    // Depending on your context, you might want to update the 'data' array or handle 'recs' differently
    // data = recs;  // Update the original data array if necessary
  };

  const calculateSections = (tasks: Task[]): Section[] => {
    const newSections: Section[] = [];
    let currentDate: Date | null = null;

    tasks.forEach((task, index) => {
      const taskDate = task.dueDate?.toDate() ?? null;
      if (!taskDate) return;

      if (
        !currentDate ||
        currentDate.toDateString() !== taskDate.toDateString()
      ) {
        currentDate = taskDate;
        newSections.push({
          number: index + newSections.length,
          text: dateFormatter.formatDate(currentDate, false),
        });
      }
    });

    return newSections;
  };

  const sectionAdjustment = (
    index: number,
    startIndex: number,
    endIndex: number,
    sections: Section[],
    isEnd: boolean
  ) => {
    return sections.reduce((acc, section, idx, arr) => {
      // Adjust only if index is moving past this section start,
      // and specifically for endIndex, check if it is landing exactly at a section start
      if (isEnd && endIndex > startIndex) {
        return acc + (index >= section.number ? 1 : 0);
      } else {
        return acc + (index > section.number ? 1 : 0);
      }
    }, 0);
  };

  const getTask = (startIndex: number, endIndex: number) => {
    let recs = [...data];
    const result = Array.from(recs);
    const adjustedStartIndex =
      startIndex -
      sectionAdjustment(startIndex, startIndex, endIndex, sections, false);
    const [removed] = result.splice(adjustedStartIndex, 1);

    return removed;
  };

  const backupFutureTasks = (recs: Task[]) => {
    if (!props.todayView) {
      futureTasks.current = [];

      return;
    }

    if (futureTasks.current.length > 0) return;

    futureTasks.current = recs
      .filter((task) => {
        return (
          task.dueDate!.toDate().setHours(0, 0, 0, 0) >
          new Date().setHours(0, 0, 0, 0)
        );
      })
      .map((task) => task.id as string);
  };

  useEffect(() => {
    backupFutureTasks(props.data);

    setData(props.data);
    if (props.todayView || props.upcomingView) {
      const newSections = calculateSections(props.data);
      setSections(newSections);
    } else {
      setSections([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.todayView, props.upcomingView]);

  useEffect(() => {
    if (listref.current) {
      listref.current.scrollTop = 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listID, props.completedMode, props.upcomingView, props.todayView]);

  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: Task[] = reorder(
      data,
      result.source.index,
      result.destination.index
    );

    // Filter out any undefined IDs
    const taskIDs: string[] = items
      .map((item) => item.id)
      .filter((id): id is string => id !== undefined);

    if (props.todayView && futureTasks.current.length > 0) {
      taskIDs.push(...futureTasks.current);
    }

    setData(items);

    //  setData(items);
    if (taskIDs != null && !props.todayView && !props.upcomingView) {
      updateProjectOrder(
        taskIDs!,
        auth.authGetUID(),
        dataProvider.getActiveProject().toString()
      );
    }

    let t = getTask(result.source.index, result.destination.index);
    let newDueDate = getNewDueDate(
      result.source.index,
      result.destination.index
    );

    if (props.todayView || props.upcomingView) {
      if (t.dueDate?.toDate().toDateString() === newDueDate.toDateString()) {
        updateProjectOrder(taskIDs!, auth.authGetUID(), "calendar");
      } else {
        updateProjectAndTask(
          taskIDs!,
          auth.authGetUID(),
          dataProvider.getActiveProject().toString(),
          false,
          Timestamp.fromDate(newDueDate!),
          t.id!
        );
      }
    }
  };

  const onDragStart = () => {
    if (!isMobile) closeNewTask();
  };

  const onMoveToTop = async (taskID: string) => {
    if (listref.current && !props.todayView && !props.upcomingView) {
      listref.current.scrollTop = 0;
    }

    const currentTask = data.find((task) => task.id === taskID);

    if (!currentTask) return;
    await props.onMoveToTop(taskID);

    if (props.todayView || props.upcomingView) return;
    const updatedData = [
      currentTask,
      ...data.filter((task) => task.id !== taskID),
    ];

    setData(updatedData);
  };

  const getNewDueDate = (startIndex: number, destinationIndex: number) => {
    if (destinationIndex === 0) return datesRef.current[0];
    let index = destinationIndex;
    if (startIndex > destinationIndex) index--;

    return datesRef.current[index];
  };

  const onFlagTask = async (taskID: string, flag: boolean) => {
    closeNewTask();
    if (!flag) {
      flagTask(false, auth.authGetUID(), taskID);
      return;
    }

    if (listref.current && !props.todayView && !props.upcomingView) {
      listref.current.scrollTop = 0;
    }

    const currentTask = data.find((task) => task.id === taskID);

    if (!currentTask) return;

    if (!props.todayView && !props.upcomingView) {
      const updatedData = [
        currentTask,
        ...data.filter((task) => task.id !== taskID),
      ];

      setData(updatedData);
    }

    props.onMoveToTop(taskID);

    setTimeout(() => {
      flagTask(true, auth.authGetUID(), taskID);
    }, 100); // Delay of 1000 milliseconds (1 second)
  };

  const renderTasks = () => {
    let sectionOffset = 0;
    let d: Date[] = [];

    const tasksAndSections: React.ReactNode[] = [];

    data.forEach((item, index) => {
      const section = sections.find((s) => s.number === index + sectionOffset);
      if (section) {
        // Increment the section offset each time a section is inserted.
        sectionOffset++;
        if (props.upcomingView || props.todayView)
          d.push(item.dueDate?.toDate()!);
        // Push the section header to the array
        tasksAndSections.push(
          <SectionHeader
            drag_id={`section-${item.dueDate
              ?.toDate()!
              .toDateString()} - ${index}`}
            key={`section-${item.dueDate?.toDate()!.toDateString()} - ${index}`}
            sectionName={section.text}
            index={section.number}
          />
        );
      }
      if (props.upcomingView || props.todayView)
        d.push(item.dueDate?.toDate()!);
      // Always push the task item to the array
      tasksAndSections.push(
        <TaskItem
          userTimeZone={props.userTimeZone}
          todayView={props.todayView}
          showListName={props.todayView || props.upcomingView}
          upcomingView={props.upcomingView}
          isMobile={isMobile}
          onMoveToTop={onMoveToTop}
          onTaskClick={props.onTaskClick}
          onTaskCompleted={props.onTaskCompleted}
          taskdone={props.completedMode}
          showDescription={props.showDescription}
          showReminders={props.showReminders}
          onTaskFlag={onFlagTask}
          userID={auth.authGetUID()}
          flagged={item.priority === 1}
          key={item.id}
          item={item}
          index={index + sectionOffset}
        />
      );
    });
    //  console.log(d);
    datesRef.current = d;

    return tasksAndSections;
  };

  return (
    <div
      ref={listref}
      style={{ justifyContent: !props.isMobile ? "center" : "" }}
      className="list_drag"
    >
      <div
        style={{ width: !props.isMobile ? "50%" : "100%" }}
        className="list_tasks"
      >
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="droppable">
            {(provided, snapshot): JSX.Element => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                onClick={(e) => {
                  closeNewTask();
                }}
              >
                {renderTasks()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default List;

import React, {
  useState,
  useEffect,
  useRef,
  CSSProperties,
  ReactNode,
} from "react";
import Button from "../../Atoms/Button/Button";

import Tooltip from "@mui/material/Tooltip";
import ReactDOM from "react-dom";

interface PoupProps {
  backgroundColor: string;
  tooltip?: string;
  buttonLabel?: string;
  textColor?: string;
  isOpen?: boolean;
  checkBoxMenu?: boolean;
  rightClickMenu?: boolean;
  buttonBackgroundColor?: string;
  keepOpenAfterClick?: boolean;
  checkBoxValue?: boolean[];
  buttonColor?: string;
  buttonFontSize?: string;
  iconComponent: React.ReactNode;
  marginTop?: string;
  mouseY?: number;
  mouseX?: number;
  items: Item[];
  onMenuHide?: () => void;
  textSize: number;
  displayBelowParent?: boolean; // New prop
}

type Item = {
  label: string;
  onClick: (event?: React.MouseEvent<HTMLElement>) => void;
  color: string;
  iconComponent: ReactNode;
};

const PopupMenu = (props: PoupProps) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);
  const [contentHeight, setContentHeight] = useState<string>("0px");

  const portalNode = useRef<HTMLDivElement | null>(null);

  const node = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (event: any) => {
    if (
      node.current?.contains(event.target as Node) ||
      portalNode.current?.contains(event.target as Node)
    ) {
      // Click occurred inside the menu or the portal, do not close
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (!isOpen && props.onMenuHide) props.onMenuHide();
    setContentHeight(`${contentRef.current?.scrollHeight}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (props.isOpen !== undefined) setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const onItemClick = (onClick: any, e: React.MouseEvent<HTMLElement>) => {
    onClick(e);
    if (props.keepOpenAfterClick) return;
    setIsOpen(false);
  };

  const menuStyle: CSSProperties = {
    position: "absolute",
    backgroundColor: props.backgroundColor,
    color: props.textColor,
    height: isOpen ? contentHeight : "0",
    visibility: isOpen ? "visible" : "hidden",
    borderRadius: "10px",
    top: props.displayBelowParent ? "100%" : "0",
    opacity: isOpen ? 1 : 1,
    right: "0",
    border: props.rightClickMenu ? "1px solid lightgray" : "none",
    transition: "visibility 0.3s,height 0.3s",
    zIndex: 1,
    whiteSpace: "nowrap",
    marginTop: props.marginTop || "",
    overflow: "hidden", // this is important, it hides the children during collapse
  };

  const parentStyle: CSSProperties = {
    userSelect: "none",
    display: "flex",
    position: "relative",
  };

  if (props.rightClickMenu) {
    if (!isOpen) return <></>;

    const menu =
      props.rightClickMenu && props.isOpen
        ? ReactDOM.createPortal(
            <div
              ref={portalNode}
              style={{
                position: "fixed", // Use 'fixed' to position relative to the viewport
                top: `${props.mouseY! + 0}px`, // Use mouseY prop for vertical positioning
                left: `${props.mouseX! + 100}px`, // Use mouseX prop for horizontal positioning
                zIndex: 1000, // Ensure the menu is on top

                // Additional styles as needed
              }}
            >
              <div style={menuStyle} ref={contentRef}>
                <div
                  style={{
                    fontSize: `${props.textSize}px`,
                  }}
                  className="parent"
                >
                  {props.items.map((item, index) => (
                    <div key={item.label} style={{}}>
                      <Button
                        id={"btn_popup" + item.label}
                        padding="12px"
                        textButtonColor={item.color}
                        label={item.label}
                        iconComponent={item.iconComponent}
                        marginAfterIcon="14px"
                        textButton={true}
                        onClick={(e) => onItemClick(item.onClick, e)}
                      ></Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>,
            document.body // Append directly to the body
          )
        : null;

    return menu;
  }

  return (
    <Tooltip
      enterDelay={1000}
      placement="left"
      title={isOpen ? "" : props.tooltip ? props.tooltip : ""}
      arrow
    >
      <div style={parentStyle} ref={node}>
        {!props.rightClickMenu && (
          <div style={{ cursor: "pointer" }} onClick={() => setIsOpen(!isOpen)}>
            <Button
              id="list_more"
              borderStyle={props.buttonLabel ? "1px solid lightgray" : ""}
              textButtonColor={props.buttonColor}
              backgroundColor={props.buttonBackgroundColor || ""}
              label={props.buttonLabel || ""}
              fontSize={props.buttonFontSize || ""}
              iconComponent={props.iconComponent}
              textButton={props.buttonLabel ? false : true}
              onClick={handleClick}
            ></Button>
          </div>
        )}

        <div style={menuStyle} ref={contentRef}>
          <div style={{ fontSize: `${props.textSize}px` }} className="parent">
            {props.items.map((item, index) => (
              <div key={item.label} style={{ display: "flex" }}>
                <Button
                  id={"btn_popup" + item.label}
                  padding="12px"
                  width={props.checkBoxMenu ? "100%" : ""}
                  textButtonColor={item.color}
                  label={item.label}
                  marginBforeCheckBox={props.checkBoxMenu ? "10px" : ""}
                  checkBoxRightChecked={
                    props.checkBoxMenu ? props.checkBoxValue![index] : false
                  }
                  checkBoxRight={props.checkBoxMenu}
                  iconComponent={item.iconComponent}
                  marginAfterIcon="14px"
                  textButton={true}
                  onClick={(e) => onItemClick(item.onClick, e)}
                ></Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default PopupMenu;

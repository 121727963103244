import { useOverlay } from "../../../Context/OverlayProvider";
import Dialog from "../Dialog/Dialog";
import { useContext, useEffect, useRef, useState } from "react";
import "./Style.css";
import { ThemeContext } from "../../../Context/Color";
import Icon from "../../Atoms/Icon/Icon";
import Button from "../../Atoms/Button/Button";

import TimePick from "../TimePick/TimePick";

type AgendaOptionsProps = {
  dailyAgenda: boolean;
  weekendAgenda: boolean;
  agendaTime: string;
  isMobile: boolean;
  userID: string;
  onSave: (
    dailyAgenda: boolean,
    weekendAgenda: boolean,
    agendaTime: string
  ) => void;
  onCancel: () => void;
};

const AgendaOptionsDlg = (props: AgendaOptionsProps) => {
  const themeContext = useContext(ThemeContext);
  const parentDivRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [parentDivWidth, setParentDivWidth] = useState(0);
  const [dailyAgenda, setDailyAgenda] = useState(props.dailyAgenda);
  const [weekendAgenda, setWeekendAgenda] = useState(props.weekendAgenda);

  const [agendaTime, setAgendaTime] = useState(props.agendaTime);

  const { removeOverlay } = useOverlay();

  const stringToTime = (time: string): Date => {
    // Create a new Date object for the current date
    const now = new Date();

    // Extract hours and minutes from the time string
    const [hour, minute] = time.split(":").map(Number);

    // Create a new Date object with the extracted hours and minutes
    const selectedTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hour,
      minute
    );

    // Set seconds and milliseconds to zero for precision
    selectedTime.setSeconds(0);
    selectedTime.setMilliseconds(0);

    return selectedTime;
  };

  const timeToString = (date: Date): string => {
    // Extract hours and minutes from the Date object
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Pad hours and minutes with leading zeros if necessary
    const paddedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    // Return the formatted time string
    return `${paddedHours}:${paddedMinutes}`;
  };

  useEffect(() => {
    if (parentDivRef.current)
      setParentDivWidth(parentDivRef.current.offsetWidth);
  }, [parentDivRef]);

  return (
    <Dialog
      widthDesktop="400px"
      widthMobile=""
      closeBar={true}
      isMobile={props.isMobile}
      onSave={() => {
        props.onSave(dailyAgenda, weekendAgenda, agendaTime);
      }}
      saveCancel={true}
      onCancel={() => {
        removeOverlay();
      }}
      onClose={() => {
        props.onSave(dailyAgenda, weekendAgenda, agendaTime);
      }}
    >
      <div
        ref={parentDivRef}
        style={{
          display: "flex",
          color: themeContext?.textColor,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontFamily: "Calibri",
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          Daily email reminder of your due tasks
        </div>
        <div style={{ display: "flex" }}>
          <div>Great way to make sure nothing gets missed</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            fontSize: "18px",
          }}
        >
          <Button
            iconComponent={<Icon brightModeIcon={true} />}
            id={"recieve_daily_agenda"}
            label={"Recieve Daily Agenda"}
            textButton={true}
            checkBoxRight={true}
            checkBoxRightChecked={dailyAgenda}
            onClick={() => {
              setDailyAgenda(!dailyAgenda);
            }}
          />
          {dailyAgenda && (
            <Button
              iconComponent={<Icon weekend={true} />}
              id={"recieve_weekend_agenda"}
              label={"Recieve Daily Agenda On Weekends"}
              textButton={true}
              checkBoxRight={true}
              checkBoxRightChecked={weekendAgenda}
              onClick={() => {
                setWeekendAgenda(!weekendAgenda);
              }}
            />
          )}
          {dailyAgenda && (
            <div style={{ marginTop: "16px" }}>
              <TimePick
                tooltip=""
                textAlign="left"
                parentDivBorder={1}
                focus={false}
                reminder={false}
                width={200}
                isMobile={props.isMobile}
                onInvalidTime={() => {
                  //    setValidTime(false);
                }}
                currentTime={stringToTime(agendaTime)}
                onTimeSelected={(time) => {
                  setAgendaTime(timeToString(time));
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default AgendaOptionsDlg;

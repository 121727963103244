import { Timestamp } from "firebase/firestore";
import { addDocumentToFirestore, updateDocumentInFirestore } from "./Crud";
import { DateFormat } from "../Util/Date";

export const MonthIndexToName = (index: number) => {
  switch (index) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
    default:
      return "Invalid Month Index";
  }
};

export const dayIndexToName = (index: number) => {
  switch (index) {
    case 1:
      return "Sunday";
    case 2:
      return "Monday";
    case 3:
      return "Tuesday";
    case 4:
      return "Wednesday";
    case 5:
      return "Thursday";
    case 6:
      return "Friday";
    case 7:
      return "Saturday";
    default:
      return "Invalid Day Index";
  }
};

export const formatReminderString = (
  r: Reminder,
  withTime: boolean,
  userTimeZone: string
) => {
  const dateFormatter = new DateFormat();
  let reminderDate = r.time.toDate();

  const deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let timeZoneName = "";
  if (deviceTimeZone !== userTimeZone && userTimeZone !== "")
    timeZoneName = " (" + userTimeZone + ")";

  if (
    (userTimeZone !== r.timeZoneName || userTimeZone !== deviceTimeZone) &&
    userTimeZone !== ""
  ) {
    reminderDate = new Date(
      reminderDate.toLocaleString("en-US", { timeZone: r.timeZoneName })
    );
    //console.log(reminderDate);
  }

  if (!r.recurring) {
    let formatDate = dateFormatter.formatDate(reminderDate, true);
    return formatDate + timeZoneName;
  }

  const hours = reminderDate.getHours().toString().padStart(2, "0"); // Get hours and pad with leading zero if necessary
  const minutes = reminderDate.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with leading zero if necessary
  const timeString = withTime ? `${hours}:${minutes}` : "?";

  if (r.daily) {
    if (!r.excludeWeekends) return `Every day at ${timeString}` + timeZoneName;

    return `Every weekday at ${timeString}` + timeZoneName;
  }

  if (r.weekly) {
    return (
      `Every ${dayIndexToName(r.dayOfWeek)} at ${timeString}` + timeZoneName
    );
  }

  if (r.monthly) {
    if (r.recurringMonthLastDay) {
      return `Monthly on the Last Day at ${timeString}` + timeZoneName;
    }
    return `Monthly on the ${r.dayOfMonth} at ${timeString}` + timeZoneName;
  }

  if (r.yearly) {
    return (
      `Yearly on ${MonthIndexToName(
        reminderDate.getMonth()
      )} ${reminderDate.getDate()} at ${timeString}` + timeZoneName
    );
  }

  return "";
};

export interface Reminder {
  time: Timestamp;
  recurring: boolean;
  recurringMonthLastDay: boolean;
  dayOfWeek: number;
  dayOfMonth: number;
  created: Timestamp;
  taskID: string;
  deviceToken: string;
  reminderID: number;
  deleted: boolean;
  done: boolean;
  daily: boolean;
  excludeWeekends: boolean;
  weekly: boolean;
  monthly: boolean;
  yearly: boolean;
  id?: string;
  utcOffset: number;
  timeZoneName: string;
  cronJobID: string;
}

export function fromJson(json: Reminder, sDocID: string): Reminder {
  return {
    created: json.created ?? new Date(),
    time: json.time ?? new Date(),
    taskID: json["taskID"] || "",
    deviceToken: json["deviceToken"] || "",
    reminderID: json["reminderID"] || 0,
    deleted: json["deleted"] || false,
    cronJobID: json["cronJobID"] || "",
    dayOfWeek: json["dayOfWeek"] || 0,
    recurring: json["recurring"] || false,
    recurringMonthLastDay: json["recurringMonthLastDay"] || false,
    daily: json["daily"] || false,
    done: json["done"] || false,
    dayOfMonth: json["dayOfMonth"] || 0,
    monthly: json["monthly"] || false,
    weekly: json["weekly"] || false,
    yearly: json["yearly"] || false,
    excludeWeekends: json["excludeWeekends"] || false,
    id: sDocID,
    utcOffset: json["utcOffset"] || 0,
    timeZoneName: json["timeZoneName"] || "",
  };
}

function toJson(reminder: Reminder) {
  return {
    deleted: reminder.deleted || false,
    taskID: reminder.taskID || "",
    time: reminder.time ? reminder.time.toDate() : Date.now(),
    created: reminder.created ? reminder.created.toDate() : new Date(),
    deviceToken: reminder.deviceToken || "",
    reminderID: reminder.reminderID || 0,
    utcOffset: reminder.utcOffset || 0,
    excludeWeekends: reminder.excludeWeekends || false,
    timeZoneName: reminder.timeZoneName || "",
    dayOfMonth: reminder.dayOfMonth || 0,
    recurringMonthLastDay: reminder.recurringMonthLastDay || false,
    daily: reminder.daily || false,
    done: reminder.done || false,
    weekly: reminder.weekly || false,
    monthly: reminder.monthly || false,
    yearly: reminder.yearly || false,
    recurring: reminder.recurring || false,
    dayOfWeek: reminder.dayOfWeek || 0,
    cronJobID: reminder.cronJobID || "",
  };
}

export const createDummyReminder = (userID: string) => {
  const r: Reminder = {
    taskID: "0",
    recurring: false,
    daily: false,
    dayOfMonth: 0,
    recurringMonthLastDay: false,
    weekly: false,
    done: false,
    monthly: false,
    cronJobID: "",
    excludeWeekends: false,
    yearly: false,
    dayOfWeek: 0,
    time: Timestamp.now(),
    created: Timestamp.now(),
    deviceToken: "",
    reminderID: 0,
    utcOffset: -new Date().getTimezoneOffset(),
    timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
    deleted: true,
  };

  createReminder(r, userID);
};

export type ReminderUpdate = Partial<Reminder>;

export const createReminder = async (
  reminder: Reminder,
  userID: string
): Promise<string> => {
  console.log(reminder.time.toDate());
  return addDocumentToFirestore(toJson(reminder), `users/${userID}/reminders`);
};

export const deleteReminder = async (userID: string, reminderID: string) => {
  updateReminder(userID, reminderID, {
    deleted: true,
  });
};

export const setReminderDone = async (userID: string, reminderID: string) => {
  updateReminder(userID, reminderID, {
    done: true,
  });
};

const updateReminder = async (
  userID: string,
  reminderID: string,
  doc: ReminderUpdate
) => {
  if (!userID || !reminderID) {
    return;
  }

  try {
    await updateDocumentInFirestore(
      doc,
      `users/${userID}/reminders`,
      reminderID
    );
    //   console.log("Order updated in Firestore");
  } catch (error) {
    console.error("Error updating order in Firestore", error);
  }
};
